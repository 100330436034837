// FONTS
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");

@font-face {
  font-family: "FranklinGothic";
  font-style: normal;
  font-weight: normal;
  src: local("FranklinGothic"),
    url("../assets/FranklinGothic.ttf") format("truetype");
}
@font-face {
  font-family: "Franklin Gothic";
  font-style: normal;
  font-weight: normal;
  src: local("Franklin Gothic Regular"),
    url("../assets/itcFlanklinRegular.ttf") format("truetype");
}
@font-face {
  font-family: "Franklin Gothic";
  font-style: normal;
  font-weight: normal;
  src: local("Franklin Gothic"),
    url("../assets/itcFranklin.ttf") format("truetype");
}
@font-face {
  font-family: "ITCFranklinGothic LT Book N";
  font-style: normal;
  font-weight: normal;
  src: local("ITCFranklinGothic LT Book N"),
    url("../assets/itcFlanklinBookN.ttf") format("truetype");
}
@font-face {
  font-family: "ITCFranklinGothic LT Book";
  font-style: normal;
  font-weight: normal;
  src: local("ITCFranklinGothic LT Book"),
    url("../assets/itcFranklin.ttf") format("truetype");
}
@font-face {
  font-family: "ITCFranklinGothic LT BookCn";
  font-style: normal;
  font-weight: normal;
  src: local("ITCFranklinGothic LT MedCn"),
    url("../assets/itcFranklinMed.ttf") format("truetype");
}
@font-face {
  font-family: "ITCFranklinGothic LT Med";
  font-style: normal;
  font-weight: normal;
  src: local("ITCFranklinGothic LT Med"),
    url("../assets/itcFranklinMed.ttf") format("truetype");
}
@font-face {
  font-family: "ITCFranklinGothic LT MedCn";
  font-style: normal;
  font-weight: bolder;
  src: local("ITCFranklinGothic LT MedCn"),
    url("../assets/itcFlanklinCnMedG.ttf") format("truetype");
}
@font-face {
  font-family: "ITCFranklinGothic LT BookCp";
  font-style: normal;
  font-weight: normal;
  src: local("ITCFranklinGothic LT BookCp"),
    url("../assets/itcFlanklinBookCp.ttf") format("truetype");
}

@font-face {
  font-family: "ITCFranklinGothic LT BookCp N";
  font-style: normal;
  font-weight: normal;
  src: local("ITCFranklinGothic LT BookCp N"),
    url("../assets/itcFlanklinBookCpN.ttf") format("truetype");
}

@font-face {
  font-family: "ITCFranklinGothic LT BookXCp";
  font-style: normal;
  font-weight: normal;
  src: local("ITCFranklinGothic LT BookXCp"),
    url("../assets/itcFlanklinBookXCp.ttf") format("truetype");
}

@font-face {
  font-family: "ITCFranklinGothic LT BookXCp N";
  font-style: normal;
  font-weight: normal;
  src: local("ITCFranklinGothic LT BookXCp N"),
    url("../assets/itcFlanklinBookXCpN.ttf") format("truetype");
}

/* @font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto"), url("../assets/Roboto-Regular.ttf") format("truetype");
} */

@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Bold"), url("../assets/Roboto-Bold.ttf") format("truetype");
}

//OLD
$font1: "Space Grotesk", sans-serif;
$font2: "ITCFranklinGothic LT Book", sans-serif;
$font3: "ITCFranklinGothic LT BookCn", sans-serif;
$font4: "ITCFranklinGothic LT Med", sans-serif;
$font5: "ITCFranklinGothic LT MedCn", sans-serif;
$font6: "ITCFranklinGothic LT BookCp", sans-serif;
$font7: "ITCFranklinGothic LT BookCp N", sans-serif;
$font8: "ITCFranklinGothic LT BookXCp", sans-serif;
$font9: "ITCFranklinGothic LT BookXCp N", sans-serif;
$font10: "ITCFranklinGothic LT Book N", sans-serif;
$font11: "Roboto", sans-serif;
$font12: "Roboto Bold", sans-serif;
$font13: "Franklin Gothic", sans-serif;
$font14: "Franklin Gothic Regular", sans-serif;

// NEW
$font15: "FranklinGothic", sans-serif;
$font16: "Roboto Condensed", sans-serif;

// COLORS
$pink: #e7accf;
$dark-pink: #cd97bf;
$grey-form: #d9d9d9;
$grey: #f0f0f0;
$dark-grey: #e0e0e0;
$grey-submit: #959ba7;
$grey-generic: #dcdcdc;
$white: #ffffff;
$black: #000000;
$red-error: #890707;
$red-error-background: #fff1f9;
$green: #609579;
$purple: #9b8ec3;
$dark-purple: #8d6fd1;
$purple-landing: #726b9b;
$purple-generic: #8852e2;
$marfil: #fcf0f0;
$grey-redeem: #f3f3f3;
$dark-green: #548068;

// MARGIN PADDING
$dimenXXXS: 4px;
$dimenXXS: 8px;
$dimenXS: 12px;
$dimenS: 16px;
$dimenM: 20px;
$dimenL: 24px;
$dimenXL: 32px;
$dimenXXL: 40px;
$dimenXXXL: 64px;

//MEDIA QUERIES
$mediaLG: 992px;
$mediaMinLG: 993px;
$mediaXS: 576px;

.trusted-by-list {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
