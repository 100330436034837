@import "styles.scss";

.navbar-monas {
  height: 100vh;
  width: 84px;
  z-index: 9;
  top: 0;
  left: 0;
  background: white;
  position: sticky;
  padding: $dimenL $dimenS;
  cursor: pointer;
  visibility: visible;
  transition: all 0.5s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  box-shadow: 10px 0px 25px 0px rgba(0, 0, 0, 0.12);
}

.navbar-monas.hidden {
  visibility: hidden;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.navbar-top-wrapper {
  display: flex;
  padding: 32px 16px;

  & button {
    background: transparent;
    border: 0;
  }
  @media (max-width: 991px) {
    justify-content: space-between;
    align-items: center;
  }
}

.navbar-top .my-collectionLink {
  padding: 16px 16px;
  width: 100%;
}

.navbar-inside-top {
  padding-bottom: 0;

  & img:nth-child(2) {
    max-width: 75%;
  }
}
.navbar-monas .navbar-top svg {
  width: 100%;
  max-width: 100%;
}

.navbar-monas .navbar-bottom {
  svg {
    width: 26px;
    height: 26px;
    @media (max-width: 992px) {
      display: none;
    }
  }
  background-size: cover;
  /* background-image: url('data:image/svg+xml,<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" fill="black" stroke="none"><path d="M130 575 l0 -65 142 0 143 0 -208 -208 -207 -207 47 -48 48 -47 207 207 208 208 0 -143 0 -142 65 0 65 0 0 255 0 255 -255 0 -255 0 0 -65z"/></g></svg>') */
}

.navbar-monas-dropdown {
  width: 350px;
  height: 100%;
  background-color: #fff;
  position: fixed;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 10;
  transition: all 0.5s;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.navbar-monas-dropdown a {
  text-decoration: none;
  color: black;
}

.navbar-monas-dropdown.show {
  visibility: visible;
  overflow: scroll;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  box-shadow: 10px 0px 25px 0px rgba(0, 0, 0, 0.12);
}

.navbar-monas-dropdown .logo {
  width: 100%;
  max-width: 100%;
  padding: $dimenL;
}

.navbar-monas-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-monas-dropdown .links {
  .link-redeem a {
    color: #8c55e5;
  }
}

.navbar-monas-dropdown .links li {
  border-top: 1px solid black;
  padding: $dimenL;
  cursor: pointer;
  color: #000;
  font-family: $font11;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.navbar-monas-dropdown li svg {
  width: 16px;
  height: 16px;
  margin-left: $dimenXXXS;
  margin-bottom: -2px;
}

.navbar-monas-dropdown li:hover {
  background: black;
  color: white;
  font-family: $font12;
}
.navbar-monas-dropdown li:hover a {
  color: white;
  font-family: $font12;
}

.navbar-monas-dropdown .navbar-bottom {
  padding: $dimenL $dimenL $dimenXXL $dimenL;
  .btn-navbar-close {
    @media (max-width: 992px) {
      display: none;
    }
  }
}

.navbar-monas-dropdown .navbar-bottom button {
  background: transparent;
  border: 0;
}

.navbar-monas-dropdown input[type="search"] {
  text-align: left;
  padding-left: $dimenXXL;
  background-size: 18px 18px;
  background-position: 16px center;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg width="800px" height="800px" viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M448 768A320 320 0 1 0 448 128a320 320 0 0 0 0 640z m297.344-76.992l214.592 214.592-54.336 54.336-214.592-214.592a384 384 0 1 1 54.336-54.336z" fill="%23000000" /></svg>');
}

.btn-navbar-close {
  img {
    width: 26px;
    height: 26px;
  }
}

@media all and (max-width: $mediaLG) {
  .navbar-monas {
    height: 80px;
    width: 100vw;
    z-index: 9;
    top: 0;
    left: 0;
    background: white;
    position: fixed;
    padding: $dimenS;
    cursor: pointer;
  }

  .navbar-monas .navbar-top svg {
    width: 60px;
    margin-top: $dimenS;
  }

  .navbar-monas .navbar-bottom {
    width: 32px;
    height: 40px;
    background-image: url('data:image/svg+xml,<%3Fxml version="1.0" %3F><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg width="800px" height="800px" viewBox="0 0 12 12" enable-background="new 0 0 12 12" id="Слой_1" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><rect fill="%231D1D1B" height="1" width="11" x="0.5" y="5.5"/><rect fill="%231D1D1B" height="1" width="11" x="0.5" y="2.5"/><rect fill="%231D1D1B" height="1" width="11" x="0.5" y="8.5"/></g></svg>');
  }

  .navbar-monas.hidden {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  .navbar-monas-dropdown {
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: 10;
    transition: all 0.5s;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    text-align: center;
  }

  .navbar-monas-dropdown li svg {
    display: none;
  }

  .navbar-monas-dropdown .navbar-bottom > div > div:nth-child(1) {
    order: 2;
    margin: $dimenXXL 0;
  }
}

/* navbar top desktop */
.navbar-top-desktop {
  position: absolute;
  right: 36px;
  top: 36px;
  z-index: 1;
  height: 36px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 991px) {
    display: none;
  }

  & .btn-navbar-top-profile {
    display: inline-flex;
    height: 36px;
    padding: 14px 10px;
    background-color: #fff;
    color: #000;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 6px;
    box-shadow: 0px 8px 16px 0px rgba(149, 46, 46, 0.08);
    border: 1px solid #000;
    min-width: 155px;
    width: auto;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    min-width: 120px;
    max-width: 120px;
    width: auto;

    & svg {
      margin-right: 0.5rem;
    }

    &.dropdown-toggle::before {
      display: none;
    }

    &:hover {
      background-color: #fff;
      color: #000;
      border: 1px solid #000;
    }
    &.btn:first-child:active {
      background-color: #fff;
      color: #000;
      border: 1px solid #000;
    }

    &.btn.show {
      background-color: #fff;
      color: #000;
      border: 1px solid #000;
    }
  }
  .dropdown-menu {
    min-width: 120px;
    max-width: 120px;
    width: auto;
    margin-top: 8px;
    border: 1px solid #000;
    padding: 0 !important;
    & a:last-child {
      border-bottom: none;
      &:hover {
        border-radius: 0px 0px 6px 6px;
      }
    }
    & a:first-child {
      &:hover {
        border-radius: 6px 6px 0 0;
      }
    }
    a {
      color: #121b21;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.4px;
      padding: 10px 12px;
      border-bottom: 1px solid #000;
      &:hover {
        background-color: inherit;
      }
    }
  }
}

.navbar-top-desktop input[type="search"] {
  height: 36px;
  padding-left: $dimenXXL;
  background-size: 18px 18px;
  background-position: 16px center;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg width="800px" height="800px" viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M448 768A320 320 0 1 0 448 128a320 320 0 0 0 0 640z m297.344-76.992l214.592 214.592-54.336 54.336-214.592-214.592a384 384 0 1 1 54.336-54.336z" fill="%23000000" /></svg>');
  text-align: left;
}

.form-search {
  position: relative;
  .form-control {
    padding-left: $dimenXL;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    font-family: $font11;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
    @media (max-width: 991px) {
      box-shadow: none;
      border-radius: 8px;
      border: 1px solid #000;
      padding-top: $dimenXXS;
      padding-bottom: $dimenXXS;
    }
  }
}

.navbar-top-desktop input[type="search"]:focus {
  box-shadow: none;
  border: 1px solid #ced4da;
}

.navbar-top-desktop button {
  width: 206px;
  border: 0;
  background: black;
  color: white;
  border-radius: 0.375rem;
  margin-left: $dimenL;
}

.input-search-dropdown {
  position: absolute;
  top: 48px;
  width: 312px;
  border-radius: 6px;
  display: none;
}

.navbar-monas-dropdown .input-search-container {
  display: none;
}

.input-search-container {
  position: relative;
  width: 270px;
  max-width: 270px;
  @media (max-width: 992px) {
    width: 100%;
    max-width: 100%;
  }
}

.input-search-dropdown.show {
  display: block;
}

.input-search-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ced4da;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.input-search-dropdown li {
  background: white;
  padding: $dimenS $dimenM;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $dimenM;
}
.input-search-dropdown li:hover {
  background: black;
  color: white;
  @media (max-width: 992px) {
    background: white;
    color: black;
  }
}
.input-search-dropdown {
  img {
    width: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.input-search-dropdown li:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.input-search-dropdown li:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.input-search-dropdown li:not(:last-child) {
  border-bottom: 1px solid #ced4da;
}
.input-search-dropdown ul a {
  width: 100%;
  text-decoration: none;
  color: black;
}
.input-search-dropdown li:hover a {
  color: white;
}

.btn-navbar-top {
  display: flex;
  width: 206px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  background: #000;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  font-family: $font11;
}

@media all and (max-width: $mediaLG) {
  .navbar-monas-dropdown .input-search-container {
    padding: 0 $dimenS;
    display: block;
  }
  .input-search-dropdown {
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - 270px);
    background: rgba(255, 255, 255, 0.9);
  }
  .input-search-dropdown ul {
    width: 90%;
    margin: 0 auto;
  }
}
