@import "styles.scss";

#theArtist {
  width: 100%;
  .wrapper {
    padding-bottom: calc($dimenXXXL * 2);
    background-color: $black;
  }

  /* HERO */
  .hero {
    position: relative;
    height: auto;
    width: 100%;

    @media (max-width: 991px) {
      .hero-image {
        min-height: 320px;
        object-fit: cover;
        margin-top: 80px;
        object-position: center center;
        object-view-box: inset(1% 0% 4% 7%);
      }
    }
  }

  /* GALERIA IMAGENES */
  .theArtist {
    position: relative;
  }

  .theArtist__Grid {
    columns: 2;
    column-gap: calc($dimenXXL + 5px);
    @media (max-width: 991px) {
      columns: 1;
    }

    &_box {
      width: 100%;
      break-inside: avoid;
      margin-bottom: $dimenXXL;

      &:nth-child(1) {
        @media (min-width: 992px) {
          padding-bottom: calc($dimenXXXL);
        }
      }

      &:nth-child(4) {
        @media (min-width: 992px) {
          padding-top: calc($dimenXXXL * 2);
        }
      }

      .button-artist-page {
        background-color: $purple-generic;
        color: $white;
        padding: 14px $dimenXXL;
        font-weight: 500;
        @media (max-width: 992px) {
          width: 100%;
        }
        &:hover {
          background-color: $purple-generic;
        }
      }

      .artist-name {
        font-family: $font15;
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 78px; /* 130% */
        letter-spacing: 3px;
      }

      .artist-description {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */
        font-family: $font11;
        @media (max-width: 992px) {
          font-size: 16px;
          font-weight: 300;
          line-height: 26px;
        }
      }

      img {
        max-width: 100%;
        border-radius: 10px;
      }

      .captions-image {
        padding-top: $dimenXS;
        font-feature-settings: "clig" off, "liga" off;
        font-family: $font11;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px; /* 142.857% */
      }
    }
  }

  /* CARROUSEL LOGOS */
  .carrousel-logos {
    width: 100%;
    padding-top: calc($dimenXXXL * 2);
    padding-bottom: calc($dimenXXXL * 2);
    @media (max-width: 991px) {
      padding-top: calc($dimenXXXL);
      padding-bottom: calc($dimenXXXL);
    }

    .carrousel-logos-title {
      color: #fff;
      text-align: center;
      font-family: $font16;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px; /* 141.667% */
      @media (max-width: 992px) {
        font-size: 26px;
      }
    }

    .mySwiper-items {
      position: relative;
      overflow: hidden;
      margin-top: $dimenL;
      min-height: 180px;
      .swiper-wrapper {
        padding-bottom: 0;
      }
      img {
        height: 100px;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  .logos {
    overflow: hidden;
    padding: 60px 0;
    white-space: nowrap;
    position: relative;
  }

  .logos:before,
  .logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }

  .logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(0, 0, 0));
  }

  .logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(0, 0, 0));
  }

  .logos:hover .logos-slide {
    animation-play-state: paused;
  }

  .logos-slide {
    display: inline-block;
    animation: 30s slide infinite linear;
  }

  .logos-slide img {
    height: 70px;
    margin: 0 25px;
  }

  /* ULTIMA SECCION */
  #about-artist {
    .about-artist {
      color: #fff;
      text-align: center;
      font-family: $font15;
      font-size: 58px;
      font-style: normal;
      font-weight: 400;
      line-height: 58px; /* 100% */

      @media (max-width: 991px) {
        font-size: 33px;
        line-height: 37px; /* 112.121% */
      }
    }
    .button-artist-page {
      background-color: $purple-generic;
      color: $white;
      padding: 14px $dimenXXL;
      font-weight: 500;
      margin-top: $dimenXXL;
      @media (max-width: 992px) {
        width: 100%;
      }
      &:hover {
        background-color: $purple-generic;
      }
    }
  }
}
