@import "styles.scss";

*:focus {
  outline: none;
}

#landing-page {
  background: $purple;
  margin: 0px;
  padding: 0px;
  //min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

//WELCOME

#welcome-section {
  position: relative;
  margin: 0px;
  padding: 63px $dimenXXXL;
  width: 100%;
  height: 624px;
}

.welcome-left,
.get-left {
  max-width: 687px;
  z-index: 1;
}

#welcome-section h2,
.get-left h2 {
  width: 580px;
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 65px;
  line-height: 75px;
  color: $white;
}

.welcome-left h1,
.get-left h3 {
  margin: 0px 0px $dimenXS 0px;
  width: 580px;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 75px;
  color: $white;
}

.welcome-left p,
.get-left p {
  margin: 0px;
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: $white;
}

.welcome-left-buttons,
.get-left-buttons {
  margin-top: 44px;
  width: 100%;
  height: 48px;
  gap: 20px;
}

.welcome-left a,
.get-left a,
.collection-section-bottom a,
.timeline-left > a {
  margin: 0px;
  padding: 14px 20px;
  height: 48px;
  background: $black;
  border-radius: 8px;
  border: 0px;
  font-family: $font4;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $white;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}

.welcome-left a:nth-child(2),
.get-left a:nth-child(2) {
  background: transparent;
  border: 1px $white solid;
}

.welcome-left a:hover,
.welcome-left a:active,
.welcome-left a:visited,
.get-left a:hover,
.get-left a:active,
.get-left a:visited,
.collection-section-bottom a:hover,
.collection-section-bottom a:active,
.collection-section-bottom a:visited,
.timeline-left > a:hover,
.timeline-left > a:active,
.timeline-left > a:visited {
  color: $white;
}

.welcome-left a:hover,
.get-left a:hover,
.collection-section-bottom a:hover,
.timeline-left > a:hover {
  opacity: 0.82;
}

.welcome-left a:active,
.get-left a:active,
.collection-section-bottom a:active,
.timeline-left > a:active {
  opacity: 0.64;
}

#welcome-section svg,
.get-section-container > svg {
  margin: 0px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.welcome-container {
  display: flex;
  width: 100%;
  max-width: 524px;
  overflow: hidden;
  margin-bottom: -8px;
}

@media (max-width: $mediaLG) {
  #welcome-section {
    margin: 0px;
    padding: 41px $dimenXXXL 0px $dimenXXXL;
    height: auto;
  }

  .welcome-left {
    width: 100%;
  }

  #welcome-section h2 {
    width: 100%;
    font-size: 60px;
  }

  #welcome-section h1 {
    margin: 0px 0px $dimenXL 0px;
    width: 100%;
    font-size: 65px;
  }

  .welcome-left-buttons {
    align-items: center;
    margin-top: $dimenXL;
  }

  .welcome-left-buttons a {
    width: 100%;
    max-width: 400px;
  }

  .welcome-left-buttons,
  .get-left-buttons {
    height: auto;
  }

  #welcome-section svg {
    position: static;
    width: 524px;
    height: 524px;
  }
}

@media (max-width: $mediaXS) {
  #welcome-section {
    height: 1180px;
    padding: 41px 0px 0px 0px;
  }

  .welcome-left {
    max-width: 314px;
    width: 100%;
  }

  .welcome-left-buttons {
    width: 100%;
  }

  .welcome-left-buttons a {
    width: 100%;
  }

  .welcome-container {
    margin-bottom: 0px;
    max-width: 314px;
  }

  #welcome-section svg {
    position: static;
    width: 441px;
    height: 441px;
    margin-left: -20px;
  }
}

@media (max-width: 332px) {
  #welcome-section {
    height: auto;
    //margin-bottom: $dimenXS;
  }

  #welcome-section h2 {
    font-size: 40px;
    line-height: 44px;
  }

  #welcome-section h1 {
    font-size: 40px;
    line-height: 44px;
  }

  .welcome-container {
    margin-bottom: -6px;
  }
}

// BLACK STRIPE

#black-stripe-section {
  margin: 0px;
  padding: 0px $dimenS;
  width: 100%;
  height: 44px;
  background: $black;
}

.black-stripe {
  overflow-x: hidden;
  width: 100%;
}

#black-stripe-section h6 {
  animation: moves 35s infinite linear;
  margin: 0px;
  white-space: nowrap;
  font-family: "FranklinGothic URW Cond";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1px;
  color: $purple;
}

@keyframes moves {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

//COLLECTION

#collection-section {
  position: relative;
  margin: 0px;
  padding: 84px $dimenXXXL $dimenXXXL $dimenXXXL;
  width: 100%;
  background: $green;
  gap: 232px;
  border-bottom-left-radius: 50% 52px;
  border-bottom-right-radius: 50% 52px;
}

.collection-section-container {
  z-index: 2;
  position: relative;
  margin: 0px;
  padding: 82px $dimenS;
  width: 100%;
  max-width: 1390px;
  height: 653px;
  border: 0px;
  border-radius: 24px;
  background: $dark-green;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
}

.collection-section-container > svg {
  position: absolute;
  bottom: 0px;
  left: -20px;
  margin: 0px;
  width: 653px;
  height: 653px;
}

.relative-container {
  position: relative;
  width: 100%;
  max-width: 1390px;
}

.banana-group-container {
  margin: 0px;
  position: absolute;
  top: 372px;
  right: 0px;
  overflow: hidden;
}

.banana-container {
  width: 539px;
  position: relative;
  margin: auto;
}

.collection-section-bottom {
  margin: 0px;
  padding: 0px;
  width: 100%;
  max-width: 1350px;
}

.collection-section-right {
  margin: 0px;
  padding: 0px;
  width: 48%;
  z-index: 3;
}

.collection-section-right h2 {
  margin: 0px 0px $dimenXXL 0px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 110%;
}

.collection-scroll {
  margin: 0px;
  padding: 0px $dimenXXXL 0px 0px;
  overflow: auto;
  height: 326px;
}

.collection-scroll p {
  margin: 0px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
}

.collection-scroll p span {
  margin: 0px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 31px;
}

/* Scrollbar styles */
.collection-scroll::-webkit-scrollbar,
.slide-monas::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.collection-scroll::-webkit-scrollbar-track,
.slide-monas::-webkit-scrollbar-track {
  border: 1px solid $white;
  border-radius: 10px;
}

.collection-scroll::-webkit-scrollbar-thumb,
.slide-monas::-webkit-scrollbar-thumb {
  background: $white;
  border-radius: 10px;
}

.collection-scroll::-webkit-scrollbar-thumb:hover,
.slide-monas::-webkit-scrollbar-thumb:hover {
  background: $white;
}

.collection-section-bottom {
  display: flex;
}

.collection-section-bottom-title {
  position: relative;
  margin: 0px 0px $dimenXXL 0px;
  width: 100%;
}

.collection-section-bottom-title > svg {
  position: absolute;
  top: -84px;
  left: 40px;
}

.collection-section-bottom h2 {
  margin: 0px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 110%;
}

.slide-monas {
  margin: 0px;
  padding: 0px 0px $dimenL 0px;
  width: 100%;
  max-width: 1350px;
  height: 450px;
  overflow-x: hidden;
  overflow-y: clip;
}

.slide-monas-inner {
  margin: 0px;
  padding: 0px;
  width: 3150px;
  height: 450px;
  animation: movesMonas 30s infinite linear;
}

@keyframes movesMonas {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-1800px);
  }
  100% {
    transform: translateX(0%);
  }
}

.slide-monas-bar {
  position: relative;
  margin: -1px 0px $dimenXXXL 0px;
  padding: 0px;
  height: 12px;
  background: #406350;
  width: 150%;
  max-width: 1382px;
  border: 0px;
  border-radius: 5px;
  z-index: 2;
}

.slide-monas svg {
  margin: 0px;
  height: 450px;
  width: 450px;
  min-height: 450px;
  max-width: 450px;
}

@media (max-width: 1450px) {
  .slide-monas {
    max-width: 900px;
  }

  .slide-monas-bar {
    max-width: 932px;
  }

  @keyframes movesMonas {
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(-2250px);
    }
    100% {
      transform: translateX(0%);
    }
  }
}

@media (max-width: $mediaLG) {
  #collection-section {
    padding: 0px $dimenXXXL $dimenXL $dimenXXXL;
    gap: 140px;
    border-bottom-left-radius: 100% 2%;
    border-bottom-right-radius: 0px;
  }

  .collection-section-container {
    padding: $dimenXL $dimenXXS $dimenM $dimenM;
    width: 100%;
    border-radius: 0px 0px 24px 24px;
  }

  .collection-section-bottom {
    width: 100%;
  }

  .collection-section-container > svg {
    display: none;
  }

  .collection-section-right {
    width: 100%;
  }

  .star-svg {
    width: 30px;
    height: 28px;
  }

  .collection-section-right div {
    height: 432px;
  }

  .collection-section-bottom-title {
    margin: 0px 0px $dimenXXXS 0px;
  }

  .slide-monas {
    margin: 0px;
    padding: 0px 0px $dimenL 0px;
    max-width: 720px;
    height: 360px;
  }

  @keyframes movesMonas {
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(-1800px);
    }
    100% {
      transform: translateX(0%);
    }
  }

  .slide-monas-bar {
    max-width: 752px;
    margin: -1px 0px $dimenXL 0px;
  }

  .slide-monas-inner {
    width: 2520px;
    height: 360px;
  }

  .slide-monas svg {
    height: 360px;
    width: 360px;
    min-height: 360px;
    max-width: 360px;
  }

  .collection-section-bottom a {
    width: 100%;
    max-width: 400px;
  }

  .banana-group-container {
    margin: 0px;
    position: absolute;
    top: 500px;
    left: calc(100% / 2 - 100px);
    overflow: hidden;
    height: 280px;
    width: 280px;
  }

  .banana-container {
    width: 539px;
    position: relative;
    margin: auto;
    height: 280px;
    width: 280px;
  }

  .banana-container {
    margin: 0px;
    //position: absolute;
    //overflow-x: hidden;
    //overflow-y: hidden;
    //top: 528px;
    //left: calc(100% / 2 - 64px);
    //height: 280px;
    //width: 280px;
  }

  .banana-container > svg {
    margin: 0px;
    height: 280px;
    width: 280px;
  }

  .collection-section-bottom-title > svg {
    top: -48px;
    left: 28px;
  }
}

@media (max-width: 848px) {
  .slide-monas {
    max-width: 360px;
  }

  @keyframes movesMonas {
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(-2160px);
    }
    100% {
      transform: translateX(0%);
    }
  }

  .slide-monas-bar {
    max-width: 392px;
  }
}

@media (max-width: $mediaXS) {
  #collection-section {
    padding: 0px 0px $dimenXL 0px;
    gap: 140px;
    border-bottom-left-radius: 100% 2%;
    border-bottom-right-radius: 0px;
  }

  .collection-section-container {
    padding: $dimenXL $dimenXXS $dimenM $dimenM;
    width: 100%;
    max-width: 360px;
    border-radius: 0px 0px 24px 24px;
  }

  .collection-section-bottom {
    width: 100%;
    max-width: 312px;
  }

  .slide-monas svg {
    height: 360px;
    width: 1360px;
    min-height: 360px;
    max-width: 360px;
  }

  .collection-section-bottom a {
    width: 100%;
    max-width: 312px;
  }
}

@media (max-width: 400px) {
  .collection-section-bottom {
    overflow: hidden;
  }

  .slide-monas-bar {
    position: static;
  }
}

@media (max-width: 359px) {
  .banana-group-container {
    display: none;
  }

  .collection-section-bottom {
    display: none !important;
  }
}

//HISTORIC AND EDGARDO

#historic-section,
#edgardo-section {
  margin: 0px;
  width: 100%;
}

#historic-section {
  padding: 128px $dimenXXXL $dimenL $dimenXXXL;
}

#edgardo-section {
  padding: $dimenL $dimenXXXL 128px $dimenXXXL;
}

.historic {
  margin: 0px;
  width: 100%;
  max-width: 1390px;
}

.historic-title h2 {
  margin: 0px 0px $dimenXXXL 0px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 110%;
}

.historic-bottom {
  position: relative;
}

.historic-bottom > svg {
  position: absolute;
}

.historic-bottom > svg:nth-child(1) {
  top: -132px;
  right: 80px;
}

.historic-bottom > svg:nth-child(2) {
  bottom: -80px;
  right: 80px;
}

.historic-carousel {
  width: 50%;
  position: relative;
  max-width: 665px;
}

.historic-carousel img {
  width: 100%;
}

.swiper-pagination {
  position: relative;
  bottom: -20px;
}

.historic-right {
  width: 45%;
}

.swiper-pagination-bullet-active {
  background: $white;
}

.historic-right h2 {
  margin: 0px 0px $dimenXL 0px;
  width: 100%;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 110%;
}

.edgardo {
  position: relative;
  margin: 0px;
  padding: 0px;
  width: 100%;
  max-width: 1390px;
  gap: 32px;
}

.edgardo-left {
  margin: 0px;
  width: 100%;
  max-width: 569px;
}

.edgardo-left h2 {
  margin: 0px 0px $dimenL 0px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 600;
  font-size: 62px;
  line-height: 110%;
}

.edgardo img,
.timeline img {
  margin: 0px;
  width: 100%;
  max-width: 646px;
  border: 0px;
  border-radius: 24px;
}

.edgardo-social {
  margin: $dimenXXXL 0px 0px 0px;
  gap: 40px;
}

.edgardo-social div {
  gap: 12px;
}

.edgardo-social a {
  margin: 0px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
}

.edgardo-social a:hover,
.edgardo-social a:active,
.edgardo-social a:visited {
  color: $white;
}

@media (max-width: 1350px) {
  .edgardo img,
  .timeline img {
    max-width: 480px;
  }
}

@media (max-width: 1200px) {
  .edgardo img {
    max-width: 400px;
  }
}

@media (max-width: $mediaLG) {
  #historic-section {
    padding: $dimenXXXL $dimenXXXL $dimenS $dimenXXXL;
  }

  #edgardo-section {
    padding: $dimenS $dimenXXXL $dimenXXL $dimenXXXL;
  }

  .historic-title h2,
  .historic-scroll {
    margin: 0px 0px $dimenXXL 0px;
  }

  .collection-scroll {
    padding: 0px $dimenXXL 0px 0px;
  }

  .historic-carousel {
    width: 100%;
    position: relative;
  }

  .historic-carousel img {
    width: 100%;
  }

  .swiper-pagination {
    position: relative;
    bottom: -20px;
  }

  .historic-right {
    width: 100%;
  }

  .edgardo {
    margin: 0px;
    padding: 0px;
    gap: 64px;
  }

  .edgardo-left {
    max-width: none;
  }

  .edgardo-left h2 {
    margin: 0px 0px $dimenXXL 0px;
  }

  .edgardo-scroll {
    height: auto;
    max-height: 316px;
    padding-right: $dimenL;
  }

  .edgardo-social {
    margin: $dimenXXL 0px 0px 0px;
    gap: 24px;
  }

  .edgardo img {
    width: 100%;
    max-width: 720px;
  }

  .historic-bottom > svg:nth-child(1) {
    top: -40px;
    right: 40px;
  }

  .historic-bottom > svg:nth-child(2) {
    display: none;
  }
}

@media (max-width: $mediaXS) {
  #historic-section {
    padding: $dimenXXXL $dimenXXXS $dimenS $dimenXXXS;
  }

  #edgardo-section {
    padding: $dimenS $dimenXXXS $dimenXXL $dimenXXXS;
  }

  .historic,
  .edgardo {
    max-width: 312px;
  }

  .edgardo-left {
    max-width: 312px;
  }

  .edgardo img {
    max-width: 312px;
  }
}

//TIMELINE

#timeline-section {
  position: relative;
  margin: 0px;
  width: 100%;
  //height: 1427px;
  //background: $purple-landing;
}

#timeline-section > svg {
  position: absolute;
  top: -40px;
  right: -260px;
  margin: 0px;
  transform: rotate(90deg);
  overflow: hidden;
  z-index: 2;
}

.timeline-bg {
  margin: 0px;
  padding: 128px $dimenXXXL;
  background-image: url(../components/icons/Cloud.svg);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  width: 100%;
  max-width: 1440px;
  height: 1428px;
}

.timeline {
  position: relative;
  margin: 0px;
  padding: 0px;
  width: 100%;
  max-width: calc(100vw - 128px);
  gap: 24px;
}

.timeline > svg,
.edgardo > svg {
  position: absolute;
}

.timeline > svg:nth-child(1),
.edgardo > svg:nth-child(1) {
  top: -64px;
  left: 28px;
}

.timeline > svg:nth-child(2),
.edgardo > svg:nth-child(2) {
  bottom: -64px;
  right: 28px;
}

.timeline-left {
  width: 100%;
  max-width: 424px;
}

.timeline-line {
  position: absolute;
  top: 18px;
  left: 10px;
  margin: 0px;
  padding: 0px;
  width: 1px;
  height: 100%;
  background: $white;
}

.timeline-left-container {
  position: relative;
  width: 100%;
  max-width: 369px;
}

.timeline-left-container svg {
  z-index: 1;
}

.timeline-left h2 {
  margin: 0px 0px $dimenXXXL 0px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 97.7%;
}

.timeline-left-item div {
  margin: 0px 0px $dimenS 0px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 97.7%;
  gap: 12px;
}

.timeline-left-item p {
  margin: 0px 0px $dimenXL 33px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

.timeline-left-item p a {
  margin: 0px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

.timeline-left-item p a:hover,
.timeline-left-item p a:active,
.timeline-left-item p a:visited {
  color: $white;
}

.timeline-left > a {
  width: 100%;
}

.timeline img {
  margin-right: 128px;
}

@media (max-width: 1350px) {
  .timeline img {
    margin-right: 64px;
  }
}

@media (max-width: $mediaLG) {
  #timeline-section > svg {
    display: none;
  }

  .timeline-bg {
    width: 100%;
    padding: 0px $dimenXXXL;
    background-size: inherit;
  }

  .timeline {
    width: 100%;
    max-width: none;
    gap: 16px;
  }

  .timeline-left {
    width: 100%;
    max-width: none;
  }

  .timeline-left a {
    max-width: 400px;
  }

  .timeline-left-container {
    width: 100%;
    max-width: none;
  }

  .timeline-left h2 {
    margin: 0px 0px $dimenXXL 0px;
  }

  .timeline-left > a {
    width: 100%;
    margin-bottom: $dimenXXL;
  }

  .timeline > svg:nth-child(1),
  .edgardo > svg:nth-child(1) {
    display: none;
  }

  .timeline > svg:nth-child(2),
  .edgardo > svg:nth-child(2) {
    top: -20px;
    right: 0px;
  }

  .timeline img {
    width: 100%;
    max-width: 720px;
    margin-right: 0px;
  }

  .timeline-line {
    height: 220px;
  }
}

@media (max-width: $mediaXS) {
  .timeline-bg {
    padding: 0px;
  }

  .timeline {
    max-width: 312px;
  }

  .timeline-line {
    height: 276px;
  }
}

//GET

#get-section {
  margin: 0px;
  padding: $dimenL;
  width: 100%;
  background: $pink;
}

.get-section-container {
  position: relative;
  margin: 0px;
  padding: 0px $dimenXXL;
  width: 100%;
  max-width: 1390px;
  height: 782px;
  border: 0px;
  border-radius: 24px;
  background: $dark-pink;
}

.get-left {
  position: relative;
}

.get-left > svg {
  position: absolute;
}

.get-left > svg:nth-child(1) {
  top: -120px;
  left: 60px;
}

.get-left > svg:nth-child(2) {
  top: -140px;
  left: 320px;
}

.get-left > svg:nth-child(3) {
  top: 400px;
  left: 80px;
}

.get-left > svg:nth-child(4) {
  top: 340px;
  left: 460px;
}

.get-left h2,
.get-left h3 {
  width: 100%;
}

@media (max-width: $mediaLG) {
  #get-section {
    padding: $dimenXL $dimenXXXL 0px $dimenXXXL;
  }

  .get-section-container {
    padding: 128px $dimenM 0px $dimenM;
    width: 100%;
    height: 1034px;
    border-radius: 24px 24px 0px 0px;
  }

  .get-left-buttons a {
    width: 100%;
    max-width: 400px;
  }

  .get-section-container > svg {
    position: static;
    //width: 400px;
    //height: 400px;
    bottom: 0px;
    left: -20px;
  }

  .get-left > svg:nth-child(1) {
    width: 44px;
    height: 42px;
    top: -60px;
    left: 100px;
  }

  .get-left > svg:nth-child(2) {
    width: 28px;
    height: 27px;
    top: -104px;
    left: 200px;
  }

  .get-left > svg:nth-child(3) {
    width: 44px;
    height: 42px;
    top: auto;
    left: 20px;
    bottom: -160px;
  }

  .get-left > svg:nth-child(4) {
    width: 28px;
    height: 27px;
    top: auto;
    left: 128px;
    bottom: -80px;
  }

  .get-container {
    margin-bottom: 0px;
  }
}

@media (max-width: $mediaXS) {
  #get-section {
    padding: $dimenXL 0px 0px 0px;
  }

  .get-section-container {
    max-width: 360px;
  }
}

//FOOTER

#fancy-footer {
  margin: 0px;
  padding: 46px 64px 46px 132px;
  width: 100%;
  height: 281px;
  background: $black;
  display: flex;
  align-items: self-start;
  justify-content: space-between;
}

#fancy-footer h3 {
  margin: 0px 0px $dimenXXS 0px;
  font-family: $font1;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 121%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: $white;
}

.mail-container {
  margin: $dimenXXL 0px 0px 0px;
  gap: 12px;
}

#fancy-footer a {
  margin: 0px;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  text-decoration-line: underline;
  color: $white;
}

#fancy-footer .item-redes-icono-f {
  height: 42px;
  width: 42px;
}

@media (max-width: $mediaLG) {
  #fancy-footer .footer-redes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #fancy-footer .footer-redes .footer-redes-cont .footer-redes-ico {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 30px 0px 15px 0px;
  }

  #fancy-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 16px;
    height: 100%;
  }

  .mail-container {
    margin: $dimenM 0px 0px 0px;
  }
}
