@import "../../styles/styles.scss";

.tc-en-pasos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: $dimenXXL;
  color: #86caf4;

  span {
    color: #86caf4;
    text-align: center;
    font-family: $font11;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  p {
    color: #fff;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: $font16;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 142.857% */
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: $dimenS;
    text-align: center;
  }

  .icons-payments {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $dimenS;
  }
}
