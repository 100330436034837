@import "../styles/styles.scss";

.wrapper-checkout-template {
  display: grid;
  grid-template-columns: auto 1fr;

  @media (max-width: $mediaLG) {
    display: block;
  }

  .main-wrapper {
    padding-top: calc($dimenXXL * 2);
    padding-bottom: calc($dimenXXL * 2);
    height: auto;
    @media (max-width: 992px) {
      padding-top: calc($dimenXXL * 3);
    }
  }
}
