@import "../../styles/styles.scss";

@mixin common-properties {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

@mixin title {
  color: #f3f3f3;
  font-family: $font11;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.selection-monas {
  @include common-properties;
}

.heading-dropdown {
  display: flex;
  align-items: center;
  gap: $dimenM;
}

.selection-monas-container {
  display: flex;
  flex-direction: column;
  gap: $dimenS;

  .title {
    @include title;
  }

  .content-counter {
    display: flex;
    align-items: center;
    gap: 2rem;

    label {
      color: white;
    }
  }
}

.display-mona {
  img {
    object-position: center center;
    width: 100%;
    object-fit: contain;
  }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .selection-monas {
    @include common-properties;
    .title {
      @include title;
    }
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .selection-monas {
    @include common-properties;
    .title {
      @include title;
    }
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .selection-monas {
    @include common-properties;
    .title {
      @include title;
    }
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .selection-monas {
    @include common-properties;
    .title {
      @include title;
    }
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .selection-monas {
    @include common-properties;
    .title {
      @include title;
    }
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .selection-monas {
    @include common-properties;
    .title {
      @include title;
    }
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  .selection-monas {
    @include common-properties;
    .title {
      @include title;
    }
  }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .selection-monas {
    @include common-properties;
    .title {
      @include title;
    }
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .selection-monas {
    @include common-properties;
    .title {
      @include title;
    }
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .selection-monas {
    @include common-properties;
    .title {
      @include title;
    }
  }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .selection-monas {
    @include common-properties;
    .title {
      @include title;
    }
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .selection-monas {
    @include common-properties;
    .title {
      @include title;
    }
  }
}

.custom-dropdown-gap {
  gap: $dimenS;
}
