@import "./../../styles/styles.scss";

@mixin title-hero {
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: $font15;
  font-size: 112.558px;
  font-style: normal;
  font-weight: 400;
  line-height: 118.186px; /* 105% */
  @media (max-width: 992px) {
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 58px; /* 120.833% */
  }
}

@mixin section {
  position: relative;
  background-color: #000;
  height: 100vh;
  overflow: hidden;
}
#algoritmo-page-v2 {
  .hero {
    @include section;

    .hero__video {
      position: relative;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      bottom: 0;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }

    .hero__wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      gap: $dimenXL;

      .title {
        @include title-hero;
      }

      .btn-scroll {
        position: absolute;
        bottom: 3rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          background: transparent;
          border: 0;
        }
      }
    }
  }

  .section-mona {
    @include section;
    @media (max-width: 992px) {
      height: auto;
    }

    .mona__video {
      position: relative;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      bottom: 0;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
  }

  .section-info {
    @include section;
    background: linear-gradient(180deg, #5e33a2 0%, #9d60dc 100%);
    min-height: 100vh;
    @media (max-width: 992px) {
      min-height: auto;
      height: auto;
    }

    .info-wrapper {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: calc($dimenXL + $dimenXL);
      height: 100%;
      align-items: center;
      justify-content: center;
      @media (max-width: 992px) {
        grid-template-columns: 1fr;
        height: auto;
        padding-top: $dimenXXL;
        padding-bottom: $dimenXXL;
      }

      .info-wrapper-text {
        grid-row: 1;
        @media (max-width: 992px) {
          grid-row: 2;
        }
        .title {
          color: #fff;
          font-feature-settings: "clig" off, "liga" off;
          font-family: $font15;
          font-size: 50px;
          font-style: normal;
          font-weight: 400;
          line-height: 50px;
          @media (max-width: 992px) {
            font-size: 40px;
            text-align: center;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            display: none;
          }
        }
        .text {
          color: #fff;
          font-feature-settings: "clig" off, "liga" off;
          font-family: $font11;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          @media (max-width: 992px) {
            text-align: center;
            font-family: $font11;
            font-size: 22px;
            font-style: normal;
            font-weight: 300;
            line-height: 30px; /* 136.364% */
          }
        }
      }

      .info-wrapper-table {
        .title-mobile {
          display: none;
          @media (max-width: 992px) {
            color: #fff;
            text-align: center;
            font-feature-settings: "clig" off, "liga" off;
            font-family: $font15;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px; /* 125% */
            display: block;
            margin-top: 3rem;
          }
        }
        .flex-table-containar {
          display: flex;
          align-items: flex-end;

          .table-header {
            border-radius: 10px 10px 0px 0px;
            background: #c4c4c4;
            padding: 20px 0 0 20px;
            p {
              color: #5f34a3;
              font-family: $font15;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px; /* 116.667% */
              text-transform: uppercase;
              margin-bottom: 8px;
            }

            & > div > p:nth-child(2) {
              color: #000;
              font-family: $font15;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px;
              text-transform: uppercase;
            }
          }
          table {
            width: 100%;
            background: linear-gradient(
              0deg,
              #5e33a2 0%,
              #814cc2 47.86%,
              #9e61dd 100%
            );
            border-bottom-left-radius: 10px;
            box-shadow: inset -14px 0px 19px #00000036;
            @media (max-width: 992px) {
              grid-row: 2;
              margin-top: 2rem;
            }

            .first-td {
              border-right: 0px !important;
              padding: 0;
            }

            td {
              padding: 5px $dimenM;
              font-family: $font4;
              font-weight: bold;
              font-size: 20px;
              color: #fff;
            }
            td:first-child {
              border-bottom: 1px solid white;
              border-right: 1px solid white;
            }
            td:nth-child(2) {
              border-bottom: 1px solid white;
            }
            tr:last-child td {
              border-bottom: 0;
            }
            span {
              font-family: "Roboto";
              display: block;
              font-weight: normal;
              font-size: 14px;
            }
          }

          .grid-table-image {
            display: grid;
            grid-template-columns: 1fr 1fr;
            height: 100%;

            @media (max-width: 992px) {
              grid-template-columns: 1fr;
            }
          }

          .table-image {
            overflow: hidden;
            display: flex;
            height: 100%;
            align-items: flex-end;
            border-radius: 0px 10px 10px 0px;
            @media (max-width: 992px) {
              border-radius: 10px 10px 10px 10px;
              margin-top: 1rem;
              grid-row: 1;
            }

            img {
              border-radius: 0px 10px 10px 0px;
              height: calc(100% - 92.5px);
              width: 100%;
              object-fit: cover;
              @media (max-width: 992px) {
                border-radius: 10px 10px 10px 10px;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  .section-call {
    @include section;
    background-color: #fff;
    @media (max-width: 992px) {
      height: 90vh;
    }

    .call-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;

      .title {
        color: #000;
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;
        font-family: $font15;
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 118.421% */
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 3rem;
        @media (max-width: 992px) {
          color: #000;
          text-align: center;
          font-feature-settings: "clig" off, "liga" off;
          font-family: $font15;
          font-size: 23px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px; /* 139.13% */
          letter-spacing: -0.3px;
          text-transform: uppercase;
        }
      }
      .text {
        color: #000;
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;
        font-family: $font11;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 34px; /* 141.667% */
        text-align: center;
        @media (max-width: 992px) {
          color: #000;
          text-align: center;
          font-feature-settings: "clig" off, "liga" off;
          font-family: $font11;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 162.5% */
        }
      }
    }
  }
}
