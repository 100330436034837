.inner-container {
  max-width: 1440px;
  margin: auto;
  overflow: hidden;
}

.container-padding {
  padding: 0px 24px;
}

@media (max-width: 576px) {
  .inner-container {
    padding: 0px;
  }
}
