@import "styles.scss";

#main-footer {
  .main-footer {
    padding-top: $dimenXXXL;
    padding-bottom: $dimenXXXL;
    @media (max-width: 992px) {
      padding-bottom: calc($dimenXXXL * 3);
    }
    .main-footer-wrap {
      display: flex;
      justify-content: space-between;
      gap: $dimenS;
      @media (max-width: 993px) {
        flex-direction: column;
      }
    }

    .main-footer-cols {
      display: flex;
      justify-content: space-between;
      gap: $dimenXXL;
      @media (max-width: 993px) {
        flex-direction: column;
      }
    }

    .footer-col-1 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      img {
        max-width: 100%;
        @media (max-width: 993px) {
          max-width: 65%;
        }
      }
    }
    .footer-col-2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .footer-col-3 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .artbag-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: right;
      font-weight: lighter;
      font-family: $font1;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 200%; /* 20px */
      letter-spacing: 0.8px;

      @media (max-width: 993px) {
        justify-content: flex-start;
        text-align: left;
      }
    }

    .author {
      color: $white;
      font-size: 32px;
      font-weight: bold;
      font-family: $font4;
    }

    .footer-sections-title {
      color: $white;
      font-size: 16px;
      font-weight: 700;
      font-family: $font11;
      font-feature-settings: "clig" off, "liga" off;
      font-style: normal;
      line-height: 28px; /* 175% */
    }
    ul {
      list-style: none;
      padding: 0;
      margin-top: $dimenXXS;
      font-family: $font11;

      a {
        color: $white;
        text-decoration: none;
      }

      li {
        color: $white;
        font-size: 16px;
        margin-bottom: 3px;
        font-weight: 300;
        text-decoration: none;
        font-family: $font11;
        font-feature-settings: "clig" off, "liga" off;
        font-style: normal;
        line-height: 28px;
      }
    }
  }
}
