@import 'styles.scss';

*:focus {
  outline: none;
}

svg {
  vertical-align: unset !important;
}

#checkout-page {
  background: $pink;
  margin: 0px;
  padding: 0px;
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

.checkout, .redeem {
  padding: 0px $dimenL $dimenL $dimenL;
}

.checkout-left-card {
  margin: 0px;
  padding: $dimenS $dimenS $dimenXL $dimenS;
  width: 347px;
  background: $dark-pink;
  border-radius: 20px;
  gap: 20px;
}

.checkout-left-box {
  margin: 0px;
  padding: 0px;
  width: 315px;
  height: 452px;
  background: $white;
  mix-blend-mode: normal;
  box-shadow: 5px 5px 74px 5px rgba(116, 116, 116, 0.25);
  border-radius: 12px;
  position: relative;
}

.checkout-left-box svg {
  width: 100%;
  height: 243px;
}

.checkout-left-box-price-bubble {
  margin: 0px;
  padding: $dimenXS $dimenXXS;
  min-width: 120px;
  height: 42px;
  position: absolute;
  top: 24px;
  right: 24px;
  gap: 10px;
  background: $dark-grey;
  border-radius: 56px;
  color: $black;
  font-family: $font1;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
}

.checkout-left-box h4 {
  margin: 0px;
  padding: $dimenXS $dimenS $dimenXXXS $dimenS;
  font-family: $font3;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 52px;
  color: $black;
}

.checkout-left-box hr {
  margin: 0px;
}

.checkout-left-box-bottom {
  margin: 0px;
  padding: $dimenL $dimenS;
  gap: 12px;
}

.checkout-left-bottom-counter {
  gap: 12px;
}

.checkout-left-bottom-counter div {
  margin: 0px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background: $grey;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: $black;
}

.checkout-left-bottom-counter h5 {
  margin: 0px;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: $black;
}

.checkout-left-card p {
  margin: 0px;
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: $white;
}

.checkout-left {
  margin: 0px 108px 0px 0px;
  padding: 0px;
}

.checkout-right {
  margin: 0px;
  padding: 0px;
  width: 483px;
}

.checkout-right h6, .checkout-form h6 {
  margin: 0px;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: $black;
  margin-bottom: $dimenM;
}

.checkout-thumbnail {
  margin: 0px;
  padding: 0px;
  width: 103px;
  height: 93px;
  border-radius: 6px;
  border: 0px;
  object-fit: cover;
}

[data-rmiz-modal-overlay="visible"] {
  background: rgba($black, 0.60) !important;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}

.custom-zoom [data-rmiz-modal-overlay], .custom-zoom [data-rmiz-modal-img] {
  object-fit: cover;
}

.checkout-right p {
  margin: 0px;
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $black;
  margin-bottom: $dimenM;
}

.checkout-right-select {
  margin: 0px;
  padding: $dimenXXXS;
  width: 248px;
  height: 61px;
  border: 1px solid $white;
  border-radius: 4px;
  margin-bottom: $dimenXL;
}

.checkout-right-select div {
  margin: 0px;
  padding: 0px;
  width: 51px;
  height: 51px;
  background: $white;
  border-radius: 2px;
  border-color: $white;
  color: $black;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  cursor: pointer;
}

.checkout-right-select div:disabled {
  opacity: 0.2;
  cursor: default;
}

.checkout-right-select div:nth-child(1) {
  padding-bottom: 6px;
}

.rotate180 {
  transform: rotate(180deg);
}

.white-background {
  fill: $white;
}

.checkout-right-select div:hover {
  opacity: 0.82;
}

.checkout-right-select div:active {
  opacity: 0.64;
}

.checkout-right-select h6 {
  margin: 0px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: $black;
}

.checkout-right-toggle {
  margin: 0px;
  margin-bottom: 54px;
  gap: 12px;
}

.margin-printed {
  margin-bottom: $dimenXL;
}

.checkout-right-toggle svg:nth-child(1) {
  margin: 0px;
  height: 31px;
  width: 56px;
  cursor: pointer;
}

.checkout-right-toggle svg:nth-child(3) {
  margin: 0px;
  height: 14px;
  width: 14px;
  cursor: pointer;
}

.checkout-right-toggle h5 {
  margin: 0px;
  font-family: $font1;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: $black;
}

.checkout-right-box {
  margin: 0px;
  margin-bottom: $dimenL;
  padding: $dimenS;
  width: 100%;
  max-width: 483px;
  background: $dark-pink;
  border-radius: 8px;
}

.checkout-right-box-pictures {
  gap: 12px;
}

.checkout-right-box h5 {
  margin: 0px;
  color: $white;
  font-family: $font1;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: $dimenXS;
}

.checkout-right-box p {
  margin: 0px;
  width: 320px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  margin-bottom: $dimenXS;
}

.checkout-right-box h6 {
  margin: 0px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  margin-bottom: $dimenM;
}

.checkout-right-buttons {
  gap: 16px;
}

.checkout-right-buttons > a {
  width: 400px;
  text-decoration: none;
}

.checkout-right-buttons button, .checkout-right-buttons > button {
  margin: 0px;
  padding: $dimenS $dimenXXS;
  width: 400px;
  height: 48px;
  border-radius: 8px;
  border: 0px;
  background-color: $white;
  font-family: $font4;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.checkout-right-buttons > button {
  color: $white;
  background: $black;
}

.checkout-right-buttons > a button {
  color: $black;
  background: transparent;
  border: 1.5px solid $black;
  border-radius: 8px;
}

.checkout-right-buttons > button:hover, .checkout-right-buttons > a:hover {
  opacity: 0.82;
}

.checkout-right-buttons > button:active, .checkout-right-buttons > a:active {
  opacity: 0.64;
}

.back-text {
  position: absolute;
  top: 112px;
  left: 60px;
  gap: 8px;
  cursor: pointer;
}

.back-text-transfer {
  gap: 8px;
  cursor: pointer;
}

.back-text h4, .back-text-transfer h4 {
  margin: 0px;
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: $white;
}

.buyer-form-container {
  margin: 0px 0px $dimenM 0px;
  width: 437px;
  gap: 8px;
}

.buyer-form-container-field {
  position: relative;
  height: 84px;
}

.buyer-form-container-field > svg {
  margin: 0px;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 21px;
  right: 24px;
  z-index: 1;
}

.PhoneInput {
  position: relative;
  align-items: start !important;
  height: 62px !important;
  margin-bottom: $dimenXXXS !important;
  --PhoneInputCountryFlag-heigh: 48px;
}

.PhoneInputCountry {
  position: absolute;
  top: 10px;
  left: 14px;
  height: 40px;
  width: 40px;
}

.PhoneInputCountrySelect > option {
  font-family: $font1;
}

.buyer-form, .PhoneInput input {
  margin: 0px 0px $dimenXXXS 0px;
  padding: $dimenM $dimenXXXL $dimenM $dimenS;
  background: $white;
  border: 1px solid $grey-form;
  border-radius: 4px;
  font-family: $font1;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: $black;
}

.PhoneInput input {
  padding-left: 60px;
}

.buyer-form-ok, .phone-input-ok input {
  background: #F8FCF8 !important;
  background-color: #F8FCF8 !important;
  border: 1px solid #66D687 !important;
}

.buyer-form-error, .phone-input-nok input {
  background: $red-error-background!important;
  border: 1px solid $red-error !important;
}

.buyer-form-container-field > div {
  margin: 0px;
  height: 18px;
  font-family: $font1;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: $red-error;
}

.checkout-checkboxes {
  margin: 0px 0px $dimenXXL 0px;
  gap: 12px;
}

.checkout-checkboxes-item {
  width: 437px;
  height: 25px;
  gap: 12px;
}

/* Hide the browser's default checkbox */
.checkout-checkboxes-item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 25px;
  width: 25px;
  z-index: 1;
}

/* Create a custom checkbox */
.checkmark {
  margin: 0px;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border: 2px solid $black;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.checkout-checkboxes-item input:hover ~ .checkmark {
  background-color: $dark-pink;
}

/* When the checkbox is checked, add a blue background */
.checkout-checkboxes-item input:checked ~ .checkmark {
  background-color: $dark-pink;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkout-checkboxes-item input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkout-checkboxes-item .checkmark:after {
  width: 5px;
  height: 10px;
  border: solid $black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkout-checkboxes label {
  margin: 0px;
  font-family: $font4;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $black;
}

.checkout-checkboxes label span {
  margin: 0px;
  font-family: $font4;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $black;
  text-decoration: underline;
  cursor: pointer;
}

.checkout-submit-buttons {
  gap: 16px;
  margin-bottom: $dimenXL;
}

.checkout-form button, .transfer-container button {
  margin: 0px;
  width: 437px;
  height: 48px;
  background: $black;
  border-radius: 8px;
  border: 0px;
  font-family: $font4;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $white;
  gap: 8px;
}

.checkout-form button:hover, .transfer-container button:hover {
  opacity: 0.82;
}

.checkout-form button:active, .transfer-container button:active {
  opacity: 0.64;
}

.checkout-form button:disabled, .checkout-right-buttons button:disabled {
  border: 0px;
  background: $grey;
  color: $grey-submit;
  cursor: default;
}

.checkout-form button:disabled:hover, .checkout-form button:disabled:active,
.checkout-right-buttons button:disabled:hover, .checkout-right-buttons button:disabled:active {
  opacity: 1;
}

.checkout-right button:disabled path {
  fill: $grey-submit;
}

.transfer-container {
  margin: 0px;
  width: 664px;
  gap: 24px;
}

.transfer-box {
  margin: 0px;
  width: 100%;
  padding: $dimenXXL;
  background: $dark-pink;
  border-radius: 20px;
  gap: 32px;
}

.transfer-box form {
  width: 437px;
}

.transfer-box h3 {
  margin: 0px;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.2px;
  color: $black;
}

.transfer-box h4 {
  margin: 0px;
  font-family: $font1;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $black;
}

.transfer-box h5 {
  margin: 0px;
  font-family: $font4;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.4px;
  color: $black;
}

.address-box {
  margin: 0px 0px $dimenXL 0px;
  padding: $dimenL;
  width: 437px;
  background: $dark-pink;
  border-radius: 20px;
  border: 0px;
}

.address-box h5 {
  margin: 0px 0px $dimenXXXS 0px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.2px;
}

.address-box p {
  margin: 0px;
  color: $white;
  font-family: $font4;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.4px;
}

.address-box h6 {
  margin: 0px;
  color: $white;
  font-family: $font4;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  text-align: right;
  cursor: pointer;
}


@media (max-width: 1200px) {

  .checkout-left {
    margin: 0px 72px 0px 104px;
  }

}

@media (max-width: $mediaLG) {

  .checkout, .redeem {
    padding: 0px $dimenXS $dimenS $dimenXS;
  }

  .checkout-left-card {
    padding: $dimenS $dimenS $dimenXL $dimenS;
    width: 347px;
    background: $dark-pink;
    border-radius: 20px;
    gap: 20px;
  }

  .checkout-left {
    margin: 0px 0px $dimenS 0px;
  }

  .checkout-right {
    width: 347px;
  }

  .checkout-right-select {
    max-width: 347px;
    width: 100%;
  }

  .checkout-right-toggle {
    margin-bottom: $dimenXL;
  }

  .checkout-right-buttons > button, .checkout-right-buttons > a, .transfer-container button {
    width: 347px;
  }

  .buyer-form-container {
    width: 100%;
  }

  .checkout-checkboxes-item {
    width: 347px;
    height: 36px;
    gap: 12px;
  }

  .checkout-submit-buttons, .checkout-right-buttons {
    margin-bottom: $dimenS;
    width: 100%;
  }

  .checkout-right button {
    width: 100%;
  }

  .back-text {
    position: static;
    margin-bottom: $dimenXL;
  }

  .transfer-container {
    width: 347px;
  }

  .transfer-box {
    padding: $dimenS;
    gap: 24px;
  }

  .transfer-box form, .transfer-box button[type=submit] {
    width: 100%;
  }

  .address-box {
    margin: 0px 0px $dimenXL 0px;
    padding: $dimenS;
    width: 100%;
  }

  .checkout-form button {
    width: 347px;
  }

}

//CHECKOUT ERROR
.checkout-error-container {
  width: 100%;
  height: calc(100vh - 200px);
}

.checkout-error-container h2 {
  margin: 0px 0px $dimenL 0px;
  color: $black;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 44px;
  text-align: center;
}

.checkout-error-container h3 {
  margin: 0px 0px $dimenL 0px;
  color: $black;
  font-family: $font2;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 26px;
  text-align: center;
}

.error-button {
  margin: $dimenL 0px 0px 0px;
  padding: 0px $dimenM;
  height: 48px;
  border-radius: 8px;
  border: 0px;
  background: $black;
  color: $white;
  font-family: $font1;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

@media (max-width: $mediaLG) {

  .checkout-error-container {
    height: calc(100vh - 256px);
  }

  .checkout-error-container h3 {
    width: 288px;
  }

}

//SPINNER
.spinner-container {
  width: 100%;
  height: calc(100vh - 200px);
}
.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
  //background: $dark-pink;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
    //background: $white;
  }
}
@media (max-width: $mediaLG) {
  .spinner-container {
    height: calc(100vh - 256px);
  }
}

//NOTFOUND
#notfound-page {
  background: $green;
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#notfound-page h2 {
  margin: 0px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 110%;
}

@media (max-width: $mediaLG) {

  #notfound-page h2 {
    margin: 0px;
    color: $white;
    font-family: $font2;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 110%;
  }

}

