@import "./styles.scss";

#sellers-login {
  .wrapper-sellers-login {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    min-height: 100vh;

    .login-col1 {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      background: linear-gradient(225deg, #485362 0%, #151a21 88.54%);
      padding: $dimenXXL $dimenXXL;

      .headline {
        width: 100%;
        color: #fff;
        hr {
          background-color: white;
          border: 1px solid white;
          width: 100%;
        }
      }

      .login-title {
        color: #fff;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: $dimenM;
      }

      .form-label {
        color: #fff;
      }

      .btn-login {
        display: flex;
        width: 100%;
        height: 48px;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex-shrink: 0;
        border-radius: 6px;
        border: 1px solid #fff;
        background-color: transparent;
        color: #fff;
      }
    }
    .login-col2 {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-position: center;
        object-fit: cover;
      }
    }
  }
}
