@import "styles.scss";

// Item principal fancy

#perfil-monas {
  position: relative;
  margin-left: 176px;

  @media (max-width: 992px) {
    padding-right: 0;
    padding-left: 0;
    margin: 0;
  }
}

.perfil-monas {
  overflow: hidden;
}

.wrapper-item-page {
  @media (min-width: 993px) and (max-width: 1100px) {
    margin-left: 3rem;
  }
}

#item-section {
  padding: 70px 48px 70px 48px;
  @media (max-width: 992px) {
    padding: 70px 0px 70px 0px;
  }
}

.item {
  max-width: 1440px;
  width: 100%;
  height: 100%;
}

.item-banana {
  width: 100%;
  height: 100%;
}

.titulo-item {
  color: $black;
  font-family: $font5;
  font-style: normal;
  font-weight: 700;
  font-size: 104px;
  line-height: 95px;
}

.subtitulo-item {
  font-family: $font5;
  font-style: normal;
  font-size: 104px;
  -webkit-text-stroke-color: #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke: 2px #000;
  -webkit-text-fill-color: #fff;
}

.lead-item {
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  color: $black;
  font-size: 16px;
}

.item-text {
  margin-top: 90%;
}

.item-badge {
  font-family: $font3 !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 25px !important;
}

.item-redes-texto {
  font-family: $font6;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}

#item-section .item-redes-icono {
  height: 42px !important;
  width: 42px !important;
}

.item-firma,
.item-firma a u {
  font-family: $font6;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  color: $black;
}

.item-section-column {
  padding-left: 3.438rem !important;
}

// Item Sobre fancy

.iconos-emoji {
  font-size: 32px !important;
}

#item-fancy-section {
  padding: 70px 48px 70px 48px;
  @media (max-width: 992px) {
    padding: 70px 0px 70px 0px;
  }
}

.item-fancy {
  max-width: 1440px;
  width: 100%;
  height: 100%;
}

.titulo-item-fancy {
  font-family: $font5;
  font-style: bold;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: $black;
}

.boxes-fancy-card {
  row-gap: "16px";
  column-gap: "12px";
  width: 100%;
  height: 100%;
  background: #f0f0f066 !important;
  align-self: center;
  box-sizing: content-box;
  padding-top: 16px;
  padding-bottom: 16px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-content: center;
  align-items: center;
}

.box-fancy-card {
  flex-grow: 1;
  max-width: 95%;
  height: 110px;
  background-color: none;
  border-right: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 16px;
}

.titulo-box-fancy-card {
  font-family: $font2;
  color: $black;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
}

.subtitulo-box-fancy-card {
  font-family: $font2;
  color: #545454;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 2.75787px;
}

.boxDown-fancy {
  margin-top: 16px !important;
}

.titulo-boxDown-fancy-card {
  font-family: $font2;
  color: $black;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
}

.subtitulo-boxDown-fancy-card {
  font-family: $font2;
  color: $black;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}

.card {
  border-color: #f0f0f0 !important;
}
.card-fancy {
  border-color: #fff !important;
  width: 664px;
}

.card-header {
  border: unset !important;
}

.card-img {
  width: 664px;
}

.card-title {
  text-align: center;
}

.button-vender {
  font-family: $font2 !important;
  margin: 0px;
  padding: 14px 20px !important;
  height: 48px !important;
  background: #000000 !important;
  border-radius: 8px !important;
  border: 0px;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #fff !important;
  text-decoration: none;
  text-align: center !important;
  cursor: pointer;
}

.item-fancy-button {
  text-align: end !important;
}

//Más sobre el proyecto FAMO

#project-section {
  padding: 70px 48px 70px 48px;
  @media (max-width: 992px) {
    padding: 70px 0px 70px 0px;
  }
}
.project {
  margin: 0px;
  width: 100%;
  max-width: 1440px;
  padding: 40px 40px 0px 40px !important;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 40px rgba(216, 216, 216, 0.4) !important;
  border-radius: 16px !important;
}
.project-column {
  padding-right: 0px !important;
}
.project-span {
  font-family: $font7 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  padding: 8px 22px 8px 22px !important;
  margin-bottom: 20px;
}
.project-button {
  font-family: $font3 !important;
  padding: 14px 43px 14px 43px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.Mona1 {
  width: auto;
  height: 223px;
}
.Mona2 {
  width: auto;
  height: 223px;
}
.Mona3 {
  width: auto;
  height: 223px;
}

// Referencia historica

#historic-section {
  padding: 70px 48px 70px 48px !important;
  @media (max-width: 992px) {
    padding: 70px 0px 70px 0px;
  }
}

.historic {
  margin: 0px;
  width: 100%;
  max-width: 1440px;
}
.titulo-ref-historica {
  font-family: $font5;
  font-style: bold;
  font-weight: 700;
  color: $black;
  font-size: 48px;
}

.lead-ref-historica {
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  color: $black;
  font-size: 16px;
}

.subtitulo-ref-historica {
  font-family: $font4;
  font-style: bold;
  font-weight: 700;
  color: $black;
  font-size: 26px;
}

.sublead-ref-historica {
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  color: $black;
  font-size: 18px;
}

.linea-ref-historica {
  width: 25px;
  margin-top: 18px;
  height: 1px;
  background-color: #000000;
}

.button-ref-historica {
  font-family: $font7 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  max-width: fit-content;
}

.box-button-ref-historica {
  min-width: 526px;
}

#historic-section {
  margin: auto;
}

#historic-section .historic-carousel-ok img {
  width: 99%;
  border-radius: 20px;
  border: 1px solid #cccccc;
  height: 100%;
  max-height: 500px;
  object-fit: contain;
}

.historic-carousel-ok {
  width: 100% !important;
  position: relative;
}

.treeButtons-ref-historica {
  overflow: auto;
  position: relative;
}

.treeButtons-ref-historica::-webkit-scrollbar {
  display: none;
}

#historic-section .new .carousel-image-items,
#historic-section .new .carousel-image {
  border: 1px solid #cccccc;
}

.parrafo-foto-historic {
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  color: $black;
  font-size: 16px;
  line-height: 23px;
  padding-top: 16px;
  padding-bottom: 16px;
  color: #616161;
}
.overflow-parrafo {
  overflow: auto;
  max-height: 350px;
  max-width: 100%;
}

.overflow-parrafo::-webkit-scrollbar {
  -webkit-appearance: none;
}

.overflow-parrafo::-webkit-scrollbar:vertical {
  width: 6px;
}

.overflow-parrafo::-webkit-scrollbar-button:increment,
.overflow-parrafo::-webkit-scrollbar-button {
  display: none;
}

.overflow-parrafo::-webkit-scrollbar:horizontal {
  height: 10px;
}

.overflow-parrafo::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  border-radius: 20px;
  border: 2px solid #e0e0e0;
}

.overflow-parrafo::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #e0e0e0;
  border: 2px solid #ffffff;
}

.overlay-historic {
  max-height: 350px;
  max-width: 85%;
}

.overlay-historic:after {
  content: linear-gradient(rgb(255 255 255 / 21%), #ffffffcf, #fff, #fff, #fff);
  height: 10%;
  width: 442px;
  position: absolute;
  margin-top: -39px;
}

p#parrafo-fin {
  color: #fff;
  margin-top: -27px;
}

// Novedades

.timeline-bg {
  background-image: unset !important;
}

.timeline-line {
  background: #000000 !important;
}

.timeline-bg {
  height: 800px !important;
}

.timeline img {
  margin-right: 0px !important;
  width: 676px !important;
  max-width: 676px !important;
  height: auto !important;
}

.timeline-left-container {
  max-width: 100% !important;
  width: 100% !important;
}

.button-checkout {
  margin: 0px;
  padding: 14px 20px;
  width: 100%;
  height: 48px;
  background: #000000;
  border-radius: 8px;
  border: 0px;
  font-family: "ITCFranklinGothic LT Med", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}

.button-checkout-dos {
  margin: 0px;
  padding: 14px 20px;
  width: auto;
  height: 48px;
  background: #000000;
  border-radius: 8px;
  border: 0px;
  font-family: "ITCFranklinGothic LT Med", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}

.button-checkout-dos:hover {
  background: #565656;
  color: #ffffff;
}

.tablets-mobile {
  display: none;
}

// Swiper

.mySwiper-items .swiper-button-prev {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='16' viewBox='0 0 25 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.357477 6.97026C-0.0330467 7.36078 -0.0330468 7.99395 0.357477 8.38447L6.72144 14.7484C7.11196 15.139 7.74513 15.139 8.13565 14.7484C8.52618 14.3579 8.52618 13.7247 8.13565 13.3342L2.4788 7.67737L8.13565 2.02051C8.52618 1.62999 8.52618 0.996823 8.13565 0.606299C7.74513 0.215774 7.11196 0.215774 6.72144 0.606299L0.357477 6.97026ZM24.9355 6.67737L1.06458 6.67737L1.06458 8.67737L24.9355 8.67737L24.9355 6.67737Z' fill='%23515151' fill-opacity='0.3'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  float: left;
  left: 34%;
  top: 4px;
  z-index: 11;
}

.mySwiper-items .swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='16' viewBox='0 0 25 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.6425 9.02973C25.033 8.63921 25.033 8.00604 24.6425 7.61552L18.2786 1.25156C17.888 0.861037 17.2549 0.861037 16.8643 1.25156C16.4738 1.64209 16.4738 2.27525 16.8643 2.66577L22.5212 8.32263L16.8643 13.9795C16.4738 14.37 16.4738 15.0032 16.8643 15.3937C17.2549 15.7842 17.888 15.7842 18.2786 15.3937L24.6425 9.02973ZM0.0644533 9.32263L23.9354 9.32263L23.9354 7.32263L0.064453 7.32263L0.0644533 9.32263Z' fill='%23515151' fill-opacity='0.3'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  float: right;
  right: 36%;
  top: 3px;
  z-index: 11;
}

.mySwiper-items .swiper-button-prev:after,
.mySwiper-items-exclusive .swiper-button-prev:after {
  content: "";
}
.mySwiper-items .swiper-button-next:after,
.mySwiper-items-exclusive .swiper-button-next:after {
  content: "";
}

.mySwiper-items .swiper-pagination-bullet,
.mySwiper-items-exclusive .swiper-pagination-bullet,
.mySwiper-collection .swiper-pagination-bullet {
  background: #d9d9d9;
  border-radius: 0px;
  width: 20px;
  margin-left: -4px !important;
}

.mySwiper-items .swiper-pagination-bullet-active,
.mySwiper-items-exclusive .swiper-pagination-bullet-active,
.mySwiper-collection .swiper-pagination-bullet-active {
  background: #000 !important;
  border-radius: 20px;
  width: 38px;
}

.mySwiper-items-exclusive .swiper-button-prev {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='16' viewBox='0 0 25 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.357477 6.97026C-0.0330467 7.36078 -0.0330468 7.99395 0.357477 8.38447L6.72144 14.7484C7.11196 15.139 7.74513 15.139 8.13565 14.7484C8.52618 14.3579 8.52618 13.7247 8.13565 13.3342L2.4788 7.67737L8.13565 2.02051C8.52618 1.62999 8.52618 0.996823 8.13565 0.606299C7.74513 0.215774 7.11196 0.215774 6.72144 0.606299L0.357477 6.97026ZM24.9355 6.67737L1.06458 6.67737L1.06458 8.67737L24.9355 8.67737L24.9355 6.67737Z' fill='%23515151' fill-opacity='0.3'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  padding: 22px;
  border-radius: 100%;
  border: 1px solid rgb(81, 81, 81, 0.3);
}

.mySwiper-items-exclusive .swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='16' viewBox='0 0 25 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.6425 9.02973C25.033 8.63921 25.033 8.00604 24.6425 7.61552L18.2786 1.25156C17.888 0.861037 17.2549 0.861037 16.8643 1.25156C16.4738 1.64209 16.4738 2.27525 16.8643 2.66577L22.5212 8.32263L16.8643 13.9795C16.4738 14.37 16.4738 15.0032 16.8643 15.3937C17.2549 15.7842 17.888 15.7842 18.2786 15.3937L24.6425 9.02973ZM0.0644533 9.32263L23.9354 9.32263L23.9354 7.32263L0.064453 7.32263L0.0644533 9.32263Z' fill='%23515151' fill-opacity='0.3'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  padding: 22px;
  border-radius: 100%;
  border: 1px solid rgb(81, 81, 81, 0.3);
}

.mySwiper-items .swiper-wrapper {
  padding-bottom: 50px;
}

// Whitelist

#whitelist-section {
  padding: 70px 48px 70px 48px;
  @media (max-width: 992px) {
    padding: 70px 0px 70px 0px;
  }
}

#whitelist-section .form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.fondocuka {
  position: absolute;
  top: -22px;
  right: -20px;
  background: url("../assets/landing/Rectangle.png") no-repeat;
  height: 44px;
  width: 161px;
}
.fondocuka p {
  margin-bottom: 0;
}
.cukarda {
  font-family: $font9;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 0px;
  color: white;
}
.whitelist-image {
  border-radius: 20.8421px;
  box-shadow: 0px 7.815789699554443px 40px 0px #00000033;
}
.whitelist {
  margin: 0px;
  width: 100%;
  max-width: 1440px;
  padding: 50px 40px 50px 40px !important;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 40px rgba(216, 216, 216, 0.4) !important;
  border-radius: 16px !important;
}
.whitelist-input {
  font-family: $font3 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-left: 25px !important;
}
.whitelist-group {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}
.form-control {
  border: 0px solid #ced4da;
}
.whitelist-button {
  font-family: $font3 !important;
  padding: 18px 34px 18px 34px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  min-width: 39%;
}
.whitelist-span {
  font-family: $font7 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  padding: 8px 22px 8px 22px !important;
  margin-bottom: 40px;
}
.whitelist-p {
  margin-bottom: 60px;
}
.whitelist-input::placeholder {
  color: #b4b4b4 !important;
  font-weight: 400;
}
.whitelist-column {
  padding-right: 98px !important;
}

.whitelist-alert {
  margin-top: 15px;
  padding: 5px !important;
}

// Mi Colección

#mi-coleccion {
  padding: 50px 0px 70px 48px;
}

.my-collection-card-container {
  overflow-x: scroll;
  width: 100%;
}

.micoleccion {
  margin: 0px;
  width: 100%;
  max-width: 1440px;
  padding: 50px 40px 50px 40px !important;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 40px rgba(216, 216, 216, 0.4) !important;
  border-radius: 16px !important;
}

.titulo-mi-coleccion {
  font-family: $font5 !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 42px !important;
  line-height: 58px !important;
  color: #000000 !important;
}

.numero-mi-coleccion {
  font-family: $font4 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: 58px !important;
  color: #000000 !important;
}

.card-mi-coleccion {
  border-color: none !important;
  width: 550px;
}

.card-header {
  border: unset !important;
}

.card-img-mi-coleccion {
  width: 550px;
}

.item-text-mi-coleccion {
  margin-top: 93%;
}

.item-badge-mi-coleccion {
  font-family: $font2 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 25px !important;
}

//modal

.modal-term-container-image {
  margin: 0px;
  padding: 0px 0px;
  width: auto;
  background: transparent;
  border-radius: 12px;
  border: 0px;
}

.modal-historysales-container-image {
  margin: 0px;
  padding: 0px 0px;
  width: auto;
  background: transparent;
  border-radius: 12px;
  border: 0px;
}

.modal-text {
  margin-top: 98%;
  margin-left: -4%;
}

.modal-card {
  border-color: none !important;
  width: 500px;
}

.modal-card-img {
  width: 500px;
}

.modal-badge {
  cursor: pointer;
}

.modal-close-badge {
  cursor: pointer;
}

.modal-close-text {
  margin-top: -17%;
  margin-right: -460px;
}

.openmona {
  cursor: pointer;
}

.modal-term-container-image {
  margin: 0px;
  padding: 0px 0px;
  width: auto;
  background: transparent;
  border-radius: 12px;
  border: 0px;
}

.modal-historysales-container-image {
  margin: 0px;
  padding: 0px 0px;
  width: auto;
  background: transparent;
  border-radius: 12px;
  border: 0px;
}

.modal-text {
  margin-top: 98%;
  margin-left: -4%;
}

.modal-card {
  border-color: none !important;
  width: 500px;
}

.modal-card-img {
  width: 500px;
}

.modal-badge {
  cursor: pointer;
}

.modal-close-badge {
  cursor: pointer;
}

.modal-close-text {
  margin-top: -17%;
  margin-right: -460px;
}

.openmona {
  cursor: pointer;
}

// Content Exclusive

#exclusive-section {
  background: url("../assets/landing/Vector.png");
  padding: 451px 48px 70px 64px !important;
  background-repeat: no-repeat;
  background-position: 0px 44px;
  background-size: cover;
  margin-bottom: 100px;
}
.exclusive {
  margin: 0px;
  width: 100%;
  max-width: 1440px;

  .exclusive-section-title {
    padding: 48px 70px 48px;
    @media (max-width: 992px) {
      padding: 0px;
    }
  }
  @media (max-width: 992px) {
    margin-left: 0;
  }
}

.exclusive-span {
  font-family: $font7 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  padding: 8px 22px 8px 22px !important;
  margin-bottom: 20px;
  color: #000000 !important;
}

.titulo-exclusive {
  font-family: $font5 !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 48px !important;
  line-height: 58px !important;
  color: #000000 !important;
}

.lead-exclusive {
  font-family: $font7 !important;
  font-style: normal;
  font-weight: 400;
  color: #000000;
  font-size: 22px;
  line-height: 24px;
}

.titulo-slider-exclusive {
  font-family: $font6;
  font-size: 31px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  color: #000000;
}

.imagen-exclusive {
  max-width: 100%;
  height: 570px;
  margin-top: 180px;
  margin-bottom: 50px;
}

.mySwiper-items-exclusive .swiper-pagination {
  bottom: 290px;
}

.iconos-emoji-textos {
  font-size: 32px !important;
}

// exclusive bloqueado

.overlay-exclusive {
  text-align: center;
  width: 100%;
  height: 1050px;
  position: absolute;
  background: rgba(248, 248, 248, 0.92);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.lead-overlay-exclusive {
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  width: 340px;
}

.enlace-overlay-exclusive u {
  font-family: $font4;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}

// Colecction

.mySwiper-collection .swiper-pagination {
  bottom: -6px;
  padding-top: 50px;
}

.dropdown-toggle::before {
  display: inline-block;
  text-align: start;
  margin-right: 0.255em;
  vertical-align: -2px;
  content: url("data:image/svg+xml,%3csvg width='20' height='12' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M2.49984 2.49992H17.4998C17.7208 2.49992 17.9328 2.41212 18.0891 2.25584C18.2454 2.09956 18.3332 1.8876 18.3332 1.66659C18.3332 1.44557 18.2454 1.23361 18.0891 1.07733C17.9328 0.921049 17.7208 0.833252 17.4998 0.833252H2.49984C2.27882 0.833252 2.06686 0.921049 1.91058 1.07733C1.7543 1.23361 1.6665 1.44557 1.6665 1.66659C1.6665 1.8876 1.7543 2.09956 1.91058 2.25584C2.06686 2.41212 2.27882 2.49992 2.49984 2.49992ZM17.4998 9.16658H2.49984C2.27882 9.16658 2.06686 9.25438 1.91058 9.41066C1.7543 9.56694 1.6665 9.7789 1.6665 9.99992C1.6665 10.2209 1.7543 10.4329 1.91058 10.5892C2.06686 10.7455 2.27882 10.8333 2.49984 10.8333H17.4998C17.7208 10.8333 17.9328 10.7455 18.0891 10.5892C18.2454 10.4329 18.3332 10.2209 18.3332 9.99992C18.3332 9.7789 18.2454 9.56694 18.0891 9.41066C17.9328 9.25438 17.7208 9.16658 17.4998 9.16658ZM17.4998 4.99992H2.49984C2.27882 4.99992 2.06686 5.08772 1.91058 5.244C1.7543 5.40028 1.6665 5.61224 1.6665 5.83325C1.6665 6.05427 1.7543 6.26623 1.91058 6.42251C2.06686 6.57879 2.27882 6.66658 2.49984 6.66658H17.4998C17.7208 6.66658 17.9328 6.57879 18.0891 6.42251C18.2454 6.26623 18.3332 6.05427 18.3332 5.83325C18.3332 5.61224 18.2454 5.40028 18.0891 5.244C17.9328 5.08772 17.7208 4.99992 17.4998 4.99992Z' fill='black'/%3e%3c/svg%3e");
  border: unset !important;
}
.dropdown-toggle::after {
  display: none !important;
}

// timeline
#timeline-section {
  margin: auto;
}

.timeline-left-item {
  width: 100%;
}

#timeline-section .tm-section .timeline-left h2 {
  font-family: $font5 !important;
  font-style: normal !important;
  font-weight: 700 !important;
}
// Noticias

#noticias-section {
  padding: 70px 48px 70px 48px;
  @media (max-width: 992px) {
    padding: 70px 0px 70px 0px;
  }
}

.noticias {
  margin: 0px;
  width: 100%;
  max-width: 1440px;
  margin-left: 176px;
  @media (max-width: 992px) {
    margin-left: 0;
  }
}
.cardContainer {
  padding: 12px 12px 0px 12px;
  border-radius: 12px !important;
  border: none;
  max-width: 278px;
  height: 448px !important;
  max-height: 448px;
}
.cardTitle {
  font-family: $font2 !important;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #1c1c1c !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cardText {
  font-family: $font2;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #53585b !important;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cardFooter {
  background-color: white !important;
  padding: 10px 0px 0px 0px !important;
}
.cardFooter p {
  margin-bottom: 0px !important;
}
.cardImageFooter {
  width: 30px !important;
  height: 30px !important;
  border-radius: 100%;
}
.footerDate {
  font-family: $font3;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
}
.dot {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
}
.footerSource {
  font-family: $font3;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
}
.href-t {
  color: #1c1c1c !important;
  text-decoration: none;
}

.titulo-noticias-section {
  font-family: $font4;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 68px;
  color: $black;
}
#noticias-section .card-img-top {
  width: 100%;
  height: 165px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#noticias-section .card-body {
  padding: 15px 0px 15px 0px;
}

#noticias-section .card {
  border: none !important;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}
#noticias-section .mySwiper-collection.mySwiper-collection-noticias {
  padding: 20px;
}

#noticias-section .card-group {
  margin-left: -26px;
}

// Collection aleatoria

#collection-aleatoria-section {
  background: #f8f8f8;
  padding: 150px 48px 150px 48px !important;
}

.collection-aleatoria {
  margin: 0px;
  width: 100%;
  max-width: 1440px;
}

.titulo-collection-aleatoria {
  font-family: $font4;
  font-style: normal;
  font-weight: 700;
  font-size: 78px;
  line-height: 78px;
  color: $black;
}

.lead-collection-aleatoria {
  font-family: $font7;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 68px;
  color: $black;
}

#botonmona .button-checkout-dos {
  width: 550px;
  height: 48px;
  display: block;
}

.mySwiper-collection-noticias .swiper-pagination,
#collection-aleatoria-section .mySwiper-collection .swiper-pagination,
#collection-aleatoria-section .mySwiper2 .swiper-pagination {
  display: none !important;
}

#collection-aleatoria-section .mySwiper2 .swiper-wrapper {
  padding: 0px !important;
}

#collection-aleatoria-section .mySwiper2 .swiper-slide {
  filter: blur(2px);
  padding: 12px;
}

#collection-aleatoria-section .mySwiper2 .swiper-slide.swiper-slide-active {
  filter: blur(0px);
}

.row {
  --bs-gutter-x: 0rem !important;
}

#collection-aleatoria-section .mySwiper-collection {
  display: none !important;
}
#collection-aleatoria-section .mySwiper-collection .swiper-slide {
  padding-right: 10px;
}

#collection-aleatoria-section .mySwiper2 .swiper-slide .card-img-mi-coleccion {
  border-radius: 20.8421px;
}

#collection-aleatoria-section .mySwiper2 .swiper-slide .card {
  border: none !important;
  background: transparent !important;
  margin: 20px;
  border-radius: 20px !important;
}

#collection-aleatoria-section .swiper-slide.swiper-slide-prev .item-image,
#collection-aleatoria-section .swiper-slide.swiper-slide-next .item-image {
  box-shadow: 0px 5.66606330871582px 28.99803352355957px 0px #00000033;
}

// buttons

.btn-dark:hover {
  color: #fff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.btn-dark:active {
  color: #fff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}

@media (max-width: 1280px) {
  #collection-aleatoria-section .mySwiper2 .swiper-wrapper {
    padding: 0px !important;
  }

  .timeline img {
    max-width: 608px;
    height: 468px;
  }
  .card-fancy {
    width: 450px;
  }
  .card-img {
    width: 450px;
    height: 450px;
  }
  .titulo-item {
    font-size: 82px;
  }

  .subtitulo-item {
    font-size: 82px;
  }
  .item-text {
    margin-top: 82%;
  }

  .item-fancy-button {
    text-align: end;
  }

  .modal-close-text {
    margin-right: -200px;
  }
  .mySwiper-items-exclusive .swiper-pagination {
    bottom: unset;
  }

  .timeline img {
    margin-right: 0px !important;
    width: 776px !important;
    max-width: 635px !important;
    height: 468px !important;
    padding-bottom: 40px;
  }
  #project-section {
    padding: 70px 48px 70px 48px;
    @media (max-width: 992px) {
      padding: 70px 0px 70px 0px;
    }
  }
  .project-cont {
    width: 80%;
  }
  .proyect-mon {
    margin-left: -100px;
  }
  .Mona1 {
    width: auto;
    height: 200px;
  }
  .Mona2 {
    width: auto;
    height: 200px;
  }
  .Mona3 {
    width: auto;
    height: 200px;
  }
  .whitelist-colrev .input-group {
    width: 100%;
  }
}
@media (max-width: 1080px) {
  .whitelist-colrev .input-group {
    width: 130%;
  }
}

@media (max-width: 1024px) {
  .item-text {
    margin-top: 90%;
  }
}

@media (min-width: 992px) {
  .is-small {
    display: none;
  }
  .is-no-small {
    display: block;
  }
  .titulo-collection-aleatoria .is-no-small {
    display: inline-block !important;
  }
}

@media (max-width: 820px) {
  .column-historic {
    margin-top: 60px !important;
  }
  .timeline-bg {
    height: 100% !important;
  }
  .modal-close-text {
    margin-right: -109px;
  }
  .imagen-exclusive {
    max-width: 100%;
    height: auto;
    margin-top: 180px;
    margin-bottom: 50px;
  }
  .card-fancy {
    width: auto;
  }
  .item-text {
    margin-top: 94%;
  }
  .whitelist-colrev .input-group {
    width: 120%;
  }
  .tablets-mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  #historic-section .historic-carousel {
    max-width: unset;
  }
  #historic-section .historic {
    max-width: unset;
  }
}

@media (max-width: 768px) {
  #collection-aleatoria-section .mySwiper2 .swiper-wrapper {
    padding: 0px !important;
  }
  .is-small {
    display: none;
  }
  .is-no-small {
    display: block;
  }
  .titulo-collection-aleatoria .is-no-small {
    display: inline-block !important;
  }
  .mySwiper-items .swiper-button-next,
  .mySwiper-items .swiper-button-prev {
    display: none !important;
  }
  .column-historic {
    margin-top: 60px !important;
  }
  .item-fancy-button {
    text-align: center;
  }
  .whitelist {
    flex-direction: column-reverse !important;
    padding: 50px 12px 50px 12px !important;
  }
  .column-historic {
    margin-top: 0px !important;
  }
  .whitelist-column {
    padding-right: 0px !important;
    padding-top: 30px;
  }
  .timeline img {
    margin-right: 0px !important;
    width: 776px !important;
    max-width: 635px !important;
    height: 468px !important;
    padding-bottom: 40px;
  }
  .item-text {
    margin-top: 89%;
  }

  .card-fancy {
    width: unset;
  }
  .whitelist-colrev .input-group {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .is-small {
    display: none;
  }
  .is-no-small {
    display: block;
  }
  .item-fancy-button {
    text-align: center;
  }
}

@media (min-width: 576px) {
  .dropdown-item-buy-fm {
    display: none !important;
  }
}

@media (max-width: 576px) {
  #noticias-section .card-group {
    margin-left: -26px;
  }
  #collection-aleatoria-section {
    padding: 70px 0px 70px 16px !important;
  }
  #noticias-section {
    padding: 70px 0px 70px 16px !important;
  }

  #noticias-section .lead-exclusive {
    line-height: 30px;
  }
  #historic-section {
    padding: 70px 16px 70px 16px !important;
  }
  #item-section {
    padding: 70px 16px 70px 16px !important;
  }
  #item-fancy-section {
    padding: 70px 16px 70px 16px !important;
  }
  #project-section {
    padding: 70px 16px 70px 16px;
  }
  .Mona1 {
    width: auto;
    height: 110px;
  }
  .Mona2 {
    width: auto;
    height: 110px;
  }
  .Mona3 {
    width: auto;
    height: 110px;
  }
  .project {
    padding: 26px 16px 0px 16px !important;
  }
  .project-button {
    width: 124%;
  }
  #timeline-section {
    padding: 70px 16px 70px 16px;
  }
  #mi-coleccion {
    padding: 30px 16px 70px 16px;
    overflow: hidden;
  }
  #exclusive-section {
    padding: 187px 16px 70px 16px !important;
    background-position-x: -197px;
    background-position-y: -17px;
    background-size: cover;
    margin-bottom: 100px;
  }

  #whitelist-section {
    padding: 70px 16px 70px 16px;
  }

  .box-button-ref-historica {
    min-width: 670px !important;
  }
  .whitelist-input {
    border-radius: 8px 8px 0px 0px !important;
    border: unset !important;
    height: 48px;
  }
  .whitelist-column {
    padding-right: 0px !important;
  }
  .whitelist-button {
    border-radius: 0px 0px 8px 8px !important;
    padding: 8px 0px 8px 0px !important;
    width: 101%;
    height: 48px;
  }
  .whitelist {
    flex-direction: column-reverse !important;
    padding: 50px 12px 12px 12px !important;
  }

  .whitelist-colrev {
    padding-top: 6%;
  }
  .whitelist-p {
    margin-bottom: 25px;
  }

  .mySwiper-items .swiper-pagination {
    margin-top: -40px !important;
  }

  .titulo-item {
    font-size: 80px;
    line-height: 79px;
  }
  .subtitulo-item {
    font-size: 80px;
    line-height: 79px;
  }

  .item-section-column {
    padding-left: 0rem !important;
  }
  .box-button-ref-historica {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .is-small,
  svg.is-small {
    display: block !important;
  }
  .is-no-small,
  svg.is-no-small {
    display: none !important;
  }
  .titulo-collection-aleatoria .is-no-small {
    display: none !important;
  }
  .titulo-ref-historica {
    font-family: $font5;
    font-style: bold;
    font-weight: 700;
    color: $black;
    font-size: 45px;
  }
  .wid {
    width: 60% !important;
  }
  .card {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  #noticias-section .card {
    display: block !important;
  }
  .card-body {
    margin-top: 1rem !important;
  }

  .titulo-box-fancy-card {
    font-size: 25px !important;
    line-height: 40px !important;
  }
  .subtitulo-box-fancy-card {
    font-size: 18px !important;
    line-height: 30px !important;
  }
  .titulo-box-fancy-card {
    font-size: 25px !important;
    line-height: 40px !important;
  }
  .subtitulo-box-fancy-card {
    font-size: 18px !important;
    line-height: 30px !important;
  }
  .timeline img {
    max-width: 328px !important;
    height: 456px !important;
  }
  .item-text {
    margin-top: 85% !important;
  }
  .titulo-item-fancy {
    font-size: 43px;
    line-height: 52px;
  }
  .box-fancy-card {
    min-width: calc(100% - 48px);
    width: 100%;
    height: 178px !important;
    border-right: unset !important;
    border-bottom: 2px solid #e0e0e0;
    /* margin: 24px !important; */
  }
  .boxes-fancy-card {
    flex-direction: column !important;
    padding: 0px !important;
  }
  .navbar-monas {
    padding: 0px 16px 0px 16px !important;
  }
  .column-historic {
    margin-top: -20px !important;
  }
  .iconos-emoji {
    margin-left: 8px;
    font-size: 46px !important;
  }
  .iconos-emoji-textos {
    font-size: 32px !important;
  }

  .item-redes-texto {
    margin-top: -22px;
  }

  .titulo-mi-coleccion {
    font-size: 35px !important;
  }

  .item-text-mi-coleccion {
    margin-top: 88%;
  }

  .modal-text {
    margin-top: 114%;
    margin-left: 36%;
  }
  .modal-close-text {
    margin-top: -22%;
    margin-right: -16px;
  }
  .modal-card {
    width: 320px;
  }

  .modal-card-img {
    width: 320px;
  }
  .item-fancy-button {
    text-align: center !important;
  }
  .columna-exclusive {
    padding: 0px 35px 0px 35px;
  }
  .mySwiper-items-exclusive .swiper-button-prev {
    display: none;
  }

  .mySwiper-items-exclusive .swiper-button-next {
    display: none;
  }

  .imagen-exclusive {
    max-width: 100%;
    height: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .boxDown-fancy {
    margin-top: 0px !important;
  }
  .navbar-button-compra {
    display: none !important;
  }

  .drop-menu {
    width: 20.6rem;
    margin-top: 1.4rem !important;
    transform: translate3d(0px, 50px, 0px);
  }

  .drop-menu li a {
    padding: 15px 0px 15px 12px;
    font-family: $font2;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.4px;
  }

  .overlay-exclusive {
    margin-top: 50px;
    height: 100%;
    width: 100%;
  }
  .lead-overlay-exclusive {
    width: 300px;
  }

  .titulo-collection-aleatoria {
    text-align: start;
    font-size: 42px;
    line-height: 78px;
  }

  .lead-collection-aleatoria {
    text-align: start;
    margin-top: -25px;
    font-size: 38px;
    line-height: 68px;
  }
  #noticias-section .cardTitle {
    font-size: 14px;
  }
  #noticias-section .card-img-top {
    width: 100%;
    height: 165px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  #noticias-section .card-body {
    margin-top: 0rem !important;
  }
  .button-vender {
    width: 100%;
  }
  #collection-aleatoria-section .mySwiper-collection {
    display: block !important;
  }
  #collection-aleatoria-section .mySwiper2 {
    display: none !important;
  }
  #collection-aleatoria-section #botonmona {
    padding-right: 16px;
  }
  .cardContainer {
    max-width: 278px;
    max-height: 454px !important;
  }
}

@media (max-width: 576px) {
  .img-monas-siluet-deck {
    zoom: 0.9;
  }
}

nav {
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
}

.margin-container {
  margin-left: -76px;
  display: flex;
  @media (max-width: 992px) {
    margin-left: 0;
  }
}
