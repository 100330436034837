@import "styles.scss";

$bgBuySticky: #fff;

#buyButtonSticky {
  height: auto;
  padding: $dimenL;
  background-color: $bgBuySticky;
  z-index: 9;

  .btn-buyButtonSticky {
    display: flex;
    width: 343px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    border: 1px solid #000;
    background: #000;
    color: $white;
    font-weight: 800;

    .button-icon {
      margin-right: 1rem;
    }
  }
  .author {
    color: $black;
    text-align: center;
    width: 100%;
    margin-top: $dimenM;
    font-weight: 800;
  }
}
