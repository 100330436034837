.wraper{
  display: flex;
  position: relative;
}

.share-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  font-size: 18px;
  position: absolute;
}

.socia-media{
  background-color: white;
  display: flex;
  align-items: center;
  padding: 8px 12px 11px 12px;
  font-size: 20px;
  border-radius: 30px;
  position: absolute;
  
}
.closeButton{
  z-index: 20;
}
.button{
  width: 20px;
  height: 20px;
  cursor: pointer;
}