@import "styles.scss";
.algoritmo-page {
  .first-section {
    position: relative;
    overflow: hidden;
    .content {
      height: 100vh;
      width: 100%;
      @media (max-width: 991px) {
        height: 80vh;
        max-height: fit-content;
      }
    }

    h1 {
      font-family: $font5;
      color: #ffffff;
      font-size: 112px;
      width: 920px;
      max-width: 90%;
      text-align: center;
      margin: 0 auto;
      padding-left: 84px;
      position: relative;
      font-feature-settings: "clig" off, "liga" off;
      font-weight: 400;
      line-height: 118.186px; /* 105% */
      @media (max-width: 991px) {
        line-height: 58px; /* 105% */
      }
    }

    button {
      position: absolute;
      left: 84px;
      right: 0;
      margin: 0 auto;
      bottom: 81px;
      background: transparent;
      border: 0;
    }

    .hero-video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #0e0e0e;
      display: flex;
      align-items: center;
      justify-content: center;

      & video {
        width: 100%;
        mix-blend-mode: exclusion;
        opacity: 0.2;
        @media (max-width: 992px) {
          height: 100%;
          width: auto;
        }
      }
    }
  }

  .second-section {
    position: relative;
    overflow: hidden;

    .content {
      height: 90vh;
      max-height: 900px;
      width: 100%;
      background: linear-gradient(180deg, #05060b 0%, #3f4149 100%);
    }

    .second-section-video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      video {
        mix-blend-mode: exclusion;
        height: 1400px;
        width: 100%;
        object-fit: cover;
        opacity: 0.2;
        transform: translateY(-258px);
      }
    }

    .second-section-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 100%;
      }
    }
  }

  .third-section {
    padding: $dimenL $dimenL $dimenXXXL 84px;
    background: linear-gradient(180deg, #5e33a2 0%, #9d60dc 100%);
    color: white;
    height: 90vh;
    max-height: 900px;
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      height: auto;
      max-height: fit-content;
    }

    .first-box {
      padding: $dimenXXL $dimenXL 0 $dimenXXL;
      color: $white;
      font-feature-settings: "clig" off, "liga" off;
      font-family: $font5;
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px; /* 100% */
      h2 {
        font-size: 48px;
        font-family: $font5;
        margin-bottom: 0;
      }
    }
    .second-box {
      padding-left: $dimenXXL;

      div {
        background: #c4c4c4;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: $dimenL;
        height: 90px;
      }
      p {
        margin-bottom: 0;
        font-family: $font5;
      }
      p:nth-child(1) {
        color: #8c52e6;
        font-size: 24px;
      }
      p:nth-child(2) {
        color: black;
        font-size: 20px;
      }
    }
    .fourth-box {
      padding: $dimenXXL $dimenXXL $dimenXL $dimenXXL;
    }
    .fifth-box {
      padding-left: $dimenXXL;
      display: flex;
      table {
        width: 100%;
        background: linear-gradient(
          0deg,
          #5e33a2 0%,
          #814cc2 47.86%,
          #9e61dd 100%
        );
        border-bottom-left-radius: 10px;
        box-shadow: inset -14px 0px 19px #00000036;

        td {
          padding: 5px $dimenM;
          font-family: $font4;
          font-weight: bold;
          font-size: 20px;
        }
        td:first-child {
          border-bottom: 1px solid white;
          border-right: 1px solid white;
        }
        td:nth-child(2) {
          border-bottom: 1px solid white;
        }
        tr:last-child td {
          border-bottom: 0;
        }
        span {
          font-family: "Roboto";
          display: block;
          font-weight: normal;
          font-size: 14px;
        }
      }
    }
    .sixth-box {
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      background-color: #eeacd8;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      overflow: hidden;
      img {
        width: 100%;
        @media (min-width: 993px) and (max-width: 1200px) {
          width: 100%;
          height: 450px;
          object-fit: cover;
        }
        @media (max-width: 992px) {
          width: auto;
          height: 400px;
        }
        @media (max-width: 600px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .fourth-section {
    margin: 0 auto;
    padding: 20vh 0;
    text-align: center;

    h2 {
      width: 960px;
      max-width: 90%;
      margin: 0 auto;
      font-family: $font5;
    }
    p {
      width: 900px;
      max-width: 85%;
      margin: 0 auto;
      font-size: 18px;
      font-family: "Roboto";
    }
  }

  @media all and (max-width: $mediaLG) {
    .first-section {
      .content {
        background-size: 90%;
      }
      h1 {
        padding-left: 0;
        font-size: 48px;
        max-width: 100%;
      }
      button {
        left: 0;
      }
    }

    .second-section {
      padding: 0;
      .content {
        background-size: 90%;
        max-height: 600px;
      }
    }

    .third-section {
      padding: $dimenL $dimenM;
      .first-box {
        order: 1;
        padding: $dimenXL 0;
        h2 {
          text-align: center;
          font-size: 40px;
        }
      }
      .sixth-box {
        order: 2;
        background-position: center center;
        border-radius: 10px;
      }
      .second-box {
        order: 3;
        padding-left: 0;
        margin-top: $dimenXL;
      }
      .fifth-box {
        order: 4;
        padding-left: 0;
      }
      .fourth-box {
        text-align: center;
        padding: $dimenXXL 0 0 0;
        order: 5;
        p {
          margin-bottom: $dimenXL;
          color: $white;
          font-feature-settings: "clig" off, "liga" off;
          font-family: $font11;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px;
        }
      }
    }
    .fourth-section {
      padding: $dimenXXXL $dimenM;
    }
  }
}
