@import "../styles/styles.scss";

@mixin common-propierties {
  display: grid;
  grid-template-columns: minmax(100px, 1fr) auto minmax(200px, 1fr);
  /* grid-template-rows: repeat(12, 1fr); */
  gap: $dimenM;
  height: 100vh;
}
#revealArtworkTemplate {
  position: relative;
  background: linear-gradient(225deg, #485362 0%, #151a21 88.54%);
  padding: 0 $dimenS;

  .revealArtworkTemplate {
    @include common-propierties;

    .area1 {
      /*  grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 13; */
      height: 100%;
    }
    .area2 {
      /* grid-column-start: 2;
      grid-column-end: 11;
      grid-row-start: 1;
      grid-row-end: 13; */
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        height: 100%;
      }
    }
    .area3 {
      /* grid-column-start: 11;
      grid-column-end: 13;
      grid-row-start: 1;
      grid-row-end: 13; */
      height: 100%;

      .reveal-mona {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        padding: $dimenM 0;
      }

      .title {
        color: #fff;
        text-align: center;
        font-family: $font15;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 123.077% */
      }

      .artwork-number {
        color: #fff;
        font-family: $font13;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 93.75% */
        text-align: center;
      }

      .box-reveal-artwork {
        border-radius: 50px;
        background: #161b22;
        padding: $dimenS;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $dimenXS;
        color: #fff;
      }
    }
  }
  /* ----------- iPad 1, 2, Mini and Air ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .revealArtworkTemplate {
      @include common-propierties;
    }
  }

  /* Portrait */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .revealArtworkTemplate {
      @include common-propierties;
    }
  }

  /* Landscape */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .revealArtworkTemplate {
      @include common-propierties;
    }
  }

  /* ----------- iPad 3, 4 and Pro 9.7" ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .revealArtworkTemplate {
      @include common-propierties;
    }
  }

  /* Portrait */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .revealArtworkTemplate {
      @include common-propierties;
    }
  }

  /* Landscape */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .revealArtworkTemplate {
      @include common-propierties;
    }
  }

  /* ----------- iPad Pro 10.5" ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
    .revealArtworkTemplate {
      @include common-propierties;
    }
  }

  /* Portrait */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .revealArtworkTemplate {
      @include common-propierties;
    }
  }

  /* Landscape */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .revealArtworkTemplate {
      @include common-propierties;
    }
  }

  /* ----------- iPad Pro 12.9" ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .revealArtworkTemplate {
      @include common-propierties;
    }
  }

  /* Portrait */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .revealArtworkTemplate {
      @include common-propierties;
    }
  }

  /* Landscape */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .revealArtworkTemplate {
      @include common-propierties;
    }
  }
}
