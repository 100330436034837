@import "../styles/styles.scss";

@mixin common-propierties {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  height: 100vh;
}

#videoRevealTemplate {
  position: relative;
  background: linear-gradient(225deg, #485362 0%, #151a21 88.54%);

  .videoRevealTemplate {
    @include common-propierties;

    .area2 {
      grid-column-start: 2;
      grid-column-end: 12;
      grid-row-start: 1;
      grid-row-end: 13;
    }
    video {
      width: 100%;
      height: 100%;
    }
  }
  /* ----------- iPad 1, 2, Mini and Air ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .videoRevealTemplate {
      @include common-propierties;
    }
  }

  /* Portrait */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .videoRevealTemplate {
      @include common-propierties;
    }
  }

  /* Landscape */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .videoRevealTemplate {
      @include common-propierties;
    }
  }

  /* ----------- iPad 3, 4 and Pro 9.7" ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .videoRevealTemplate {
      @include common-propierties;
    }
  }

  /* Portrait */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .videoRevealTemplate {
      @include common-propierties;
    }
  }

  /* Landscape */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .videoRevealTemplate {
      @include common-propierties;
    }
  }

  /* ----------- iPad Pro 10.5" ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
    .videoRevealTemplate {
      @include common-propierties;
    }
  }

  /* Portrait */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .videoRevealTemplate {
      @include common-propierties;
    }
  }

  /* Landscape */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .videoRevealTemplate {
      @include common-propierties;
    }
  }

  /* ----------- iPad Pro 12.9" ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .videoRevealTemplate {
      @include common-propierties;
    }
  }

  /* Portrait */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .videoRevealTemplate {
      @include common-propierties;
    }
  }

  /* Landscape */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .reveal-wrapper {
      @include common-propierties;
    }
  }
}
