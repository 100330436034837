@import "../../styles/styles.scss";

.transfer-method {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: $dimenXXL;
  margin: auto;
  color: #fff;

  .transfer-rows {
    display: flex;
    align-items: flex-start;
    gap: calc($dimenXXL + $dimenXL);

    strong {
      color: #86caf4;
    }
  }
  .country-account {
    padding: 8px 12px;
    border: 1px solid #86caf4;
    border-radius: 6px;
    text-align: center;
  }

  /* span {
    color: #86caf4;
    text-align: center;
    font-family: $font11;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  p {
    color: #fff;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: $font16;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
  } */

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: $dimenS;
    text-align: center;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: $dimenS;
  }
}
