@import "./styles.scss";

#comercialCheckout {
  /* Estilos para el input de tipo "number" */
  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }

  /* Para ocultar las flechas en navegadores basados en WebKit */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .wrapper-comercial-checkout {
    background: linear-gradient(225deg, #485362 0%, #151a21 88.54%);
    min-height: 100vh;
    /* padding-top: $dimenXL;
    padding-bottom: $dimenXL; */

    .container-flex {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      /* gap: $dimenXXL; */
      height: 100vh;
    }

    .headline {
      width: 100%;
      h6 {
        color: #fff;
        font-family: $font11;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        text-transform: uppercase;
      }
      & > hr {
        background-color: white;
        border: 1px solid white;
      }
    }

    .wrapper-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $dimenXL;

      .grid-col {
        display: flex;
        flex-direction: column;
        gap: $dimenM;

        .imgCard {
          width: 100%;
        }

        .counter {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
        }

        .grid-title {
          color: #f3f3f3;
          font-family: $font11;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .details__info-tooltip {
          display: flex;
          align-items: center;
          gap: $dimenM;

          label {
            color: #fff;
            font-family: $font11;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 1.3px;
          }
        }
      }
    }
  }
  .form {
    .offcanvas {
      width: 65%;
      .headline hr {
        background-color: black;
        border: 1px solid black;
        width: 100%;
      }
    }
    .offcanvas-title {
      color: $black;
    }
    .offcanvas-body {
      color: $black;
      .headline hr {
        background-color: black;
        border: 1px solid black;
        width: 100%;
      }
    }
    strong {
      color: black;
    }
    .pay-method {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #eaf3ff;
      border: 1px solid #85b9f8;
      border-radius: 6px;
      width: 100%;
      color: #80898f;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 24px;
      cursor: pointer;
      & p {
        margin-bottom: 0;
      }
    }
  }

  .btn-next {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border: 1px solid #aed3ff;
    border-radius: 6px;
    margin-left: 8px;
  }
}

#sellers-paymentMethod {
  background: linear-gradient(225deg, #485362 0%, #151a21 88.54%);
  width: 100%;
  max-height: 100vh;
  .wrapper-sellers-paymentMethod {
    display: grid;
    grid-template-columns: 1fr;
    box-sizing: border-box;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
    height: 100vh;
  }

  .payment-col1 {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding-top: $dimenXL;
    padding-bottom: $dimenXL;
  }
  .payment-col2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: $dimenXL;
    padding-bottom: $dimenXL;

    .title-param {
      color: #fff;
    }

    .method-qr {
      border-radius: 10px;
      border: 2px solid #aed3ff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $dimenL;

      .qr-wrapper {
        background-color: $white;
        border-radius: 10px;
        padding: $dimenS;
      }

      .method-qr-span {
        color: #fff;
        font-family: $font11;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: $dimenS;
      }
    }
  }
  .payment-col3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: $dimenXL;
    padding-bottom: $dimenXL;
  }

  .btn-payment-method {
    display: flex;
    width: auto;
    height: 48px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
  }
}

#totalDetail {
  .total-footer {
    padding: $dimenM;
    border: 1px solid #aed3ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    gap: $dimenXL;

    .total-footer-payment-method {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $dimenM;
      padding-right: 32px;
      border-right: 1px solid #aed3ff;

      .name-method {
        color: #fff;
        text-align: center;
        font-family: $font11;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .total-footer-col1 {
      color: #fff;
      font-feature-settings: "clig" off, "liga" off;
      font-family: $font11;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      flex: 1;

      & > strong {
        color: #aed3ff;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .total-footer-col2 {
      color: #fff;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 23px; /* 95.833% */
      padding-left: $dimenXL;
      border-left: 1px solid #aed3ff;
      & > strong {
        color: #aed3ff;
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 23px; /* 95.833% */
      }
    }
  }
}
.custom-dropdown-gap {
  gap: $dimenS;
}
