@import "./styles.scss";

#fancy-checkout {
  hr {
    margin: 0 0;
  }
  /* Estilos para el input de tipo "number" */
  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }

  /* Para ocultar las flechas en navegadores basados en WebKit */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .fancy-checkout {
    display: grid;
    grid-template-columns: 2.4fr 1fr;
    grid-template-areas:
      "content resume"
      "content .";
    gap: $dimenXL;
    margin-top: $dimenXL;

    &.pay {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content"
        "content";
    }

    @media (max-width: 992px) {
      grid-area: content;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "content";
    }

    .checkout__info {
      gap: $dimenXL;
      grid-area: content;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        "img counter counter"
        "print print print";

      @media (max-width: 992px) {
        grid-area: content;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas:
          "img"
          "counter"
          "print";
      }
    }

    .checkout__resume {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      grid-area: resume;
      position: sticky;
      top: 50px;
      @media (max-width: 992px) {
        display: none;
      }

      .table {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        .table__item {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            color: #000;
            font-family: $font11;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
          p {
            margin-bottom: 0;
          }
        }
      }
      .total {
        position: absolute;
        bottom: 32px;
        left: 32px;
      }
    }

    .checkout-resume-mobile {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: $dimenXXL $dimenXL;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      gap: $dimenXS;
      flex-direction: row-reverse;
      @media (min-width: 993px) {
        display: none;
      }
    }

    .checkout__form {
      grid-area: content;
      display: grid;
      grid-template-columns: 1fr;

      .info-edit {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .actions {
    grid-area: print;
  }

  .resume-card-buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .details {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    @media (max-width: 500px) {
      flex-direction: column;
    }
    .details__info {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: $dimenXS;

      h3 {
        color: #000;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .details__info_checkbox {
        display: flex;
        flex-direction: column;
        gap: $dimenXS;

        .checkbox-printed-selected {
          width: 30px;
          height: 30px;
          border-radius: 4px;
          border: 0.75px solid #000;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .checkbox span {
          color: #000;
          font-family: Roboto;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 100% */
          margin-right: 0.5rem;
        }

        .checkbox p {
          margin-bottom: 0;
          margin-left: 1rem;
          color: #000;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
      }
      .details__info-tooltip {
        display: flex;
        align-items: center;
        gap: $dimenM;
      }
    }
    .details-caption {
      color: #878585;
      font-family: $font11;
      font-size: 11px;
      font-style: italic;
      font-weight: 600;
      line-height: 16px; /* 145.455% */
      letter-spacing: -0.2px;
    }
    .details-counter {
      display: flex;
      align-items: center;
      gap: $dimenM;
      h2 {
        margin-bottom: 0;
      }
    }
    .display-options {
      color: #000;
      font-family: $font11;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.3px;
      &.selected {
        background-color: rgba(0, 0, 0, 0.558);
        color: white;
        padding: $dimenXXS;
        border-radius: 6px;
      }
    }
    .imgPrint {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: linear-gradient(221deg, #485362 0%, #151a21 100%);
      position: relative;
      overflow: hidden;
      aspect-ratio: 1/1;
      min-width: 180px;
      width: 180px;
      height: 180px;
      cursor: pointer;

      .icon-zoom {
        position: absolute;
        top: 12px;
        right: 12px;

        svg {
          width: 15px;
          height: 15px;
        }
      }

      .image-detail {
        width: 100%;
        padding: 30px;
      }
    }
  }

  .display-section {
    .image-detail {
      position: relative;
      width: 100%;
    }
  }
}

.imgCard {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 10px;
  background: linear-gradient(221deg, #485362 0%, #151a21 100%);
  position: relative;
  overflow: hidden;
  aspect-ratio: 1/1;
  min-width: 320px;
  width: 320px;
  height: 320px;
  grid-area: img;

  .digital-art {
    position: absolute;
    top: 12px;
    left: 12px;
    display: flex;
    align-items: center;
    gap: $dimenXS;
    .icon {
      height: 15px;
    }
    & .icon-name {
      height: 10px;
    }
  }

  .author-name {
    position: absolute;
    bottom: 67px;
    right: -43px;
    transform: rotate(-90deg);
    span {
      color: #9d9d9d;
      font-family: $font16;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1px;
    }
  }

  img {
    height: 100%;
    object-position: center bottom;
    object-fit: cover;
  }
}

.checkout__info-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $dimenM;
  grid-area: counter;

  .info {
    display: flex;
    flex-direction: column;

    .counter {
      display: flex;
      gap: $dimenXL;
      align-items: center;

      h2 {
        margin-bottom: 0;
      }
    }
  }
}

#step3 {
  .imgCard {
    width: 180px;
    min-width: 180px;
    height: 180px;
  }

  .resume-details {
    display: flex;
    flex-direction: column;
    gap: $dimenXXL;
  }
}

.grow-title {
  flex: 1;
}

.form-width {
  width: 90%;
  @media (max-width: 992px) {
    width: 100%;
  }
}
.detail-mona-display .details {
  .image-detail {
    position: relative;
    width: 100%;
  }
}

#step4 {
  grid-area: content;
  .checkout-payment {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: calc($dimenXXL + 13px);

    .checkout-payment-text {
      display: flex;
      flex-direction: column;
      gap: $dimenS;
      align-items: center;
    }
    .checkout-payment-title {
      color: #000;
      font-family: $font11;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .checkout-payment-subtitle {
      color: #000;
      font-family: $font11;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px; /* 150% */
    }

    .checkout-payment-cards {
      display: flex;
      gap: calc($dimenXXL + 13px);
      cursor: pointer;
      @media (max-width: 992px) {
        flex-direction: column;
      }
    }

    .card-method {
      border-radius: 6px;
      border: 1px solid #aed3ff;
      background-color: #f9fcff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 300px;
      transition: 0.3s ease-in;

      &.selected {
        border: 1.7px solid #aed3ff;
        transform: scale(1.03);
        transition: 0.2s ease-in-out;
      }

      span {
        color: #000;
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;
        font-family: $font11;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 100% */
      }
      .card-method-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $dimenXS;
      }
    }
  }
  .modal-method-payment {
    width: 100%;
    display: flex;
    flex-direction: column;
    .block1 {
      background: #f5f9ff;
      padding: $dimenS $dimenL;
      h3 {
        color: #2368ac;
        font-family: $font11;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 23px; /* 115% */
      }
      span {
        color: #000;
        font-family: $font11;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 34px;
      }
    }
    .block2 {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: $dimenM;
      padding-top: $dimenM;
      padding-bottom: $dimenM;
      @media (max-width: 992px) {
        grid-template-columns: 1fr;
      }

      .title {
        color: #2368ac;
        font-family: $font11;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px; /* 115% */
      }

      .transfer-option {
        display: flex;
        gap: $dimenXXS;
      }

      .zelle-option {
        border-left: 1px solid #e0e0e0;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: $dimenXXS;
        padding-left: $dimenM;
        @media (max-width: 992px) {
          border-left: 0px;
          margin-top: $dimenS;
          padding-left: 0;
        }
      }
    }
    .block3 {
      display: grid;
      grid-template-columns: 1fr;
      gap: $dimenM;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      padding-top: $dimenM;
      padding-bottom: $dimenM;

      .title {
        color: #2368ac;
        font-family: $font11;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px; /* 115% */
      }

      .cripto-option {
        display: flex;
        gap: $dimenXXS;
      }
    }

    .block4 {
      display: flex;
      flex-direction: column;
      gap: $dimenXXXS;
      padding-top: $dimenM;
      padding-bottom: $dimenM;

      h3 {
        color: #000;
        font-family: $font11;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }

      span {
        color: #9c9c9c;
        font-family: $font11;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
      }
    }
  }
}
