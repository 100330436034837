@import "styles.scss";

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.fixed-home {
  height: 100vh;
  @media (max-width: 992px) {
    height: calc(100svh - 138px);
  }
}
.home-page {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 50%;
  padding-left: 84px;
  min-height: -webkit-fill-available;
  @media (max-width: 1200px) {
    background-size: 60%;
  }
  @media (max-width: 992px) {
    background-size: 70%;
  }
  @media (max-width: 768px) {
    background-size: 100%;
  }
  @media (min-width: 650px) and (max-width: 767px) {
    background-size: 80%;
  }
}
.button-random {
  background: transparent;
  border: 0;
  position: absolute;
  bottom: 3rem;
  right: 3rem;
}
.button-random img {
  width: 44px;
  animation: zoom 2s infinite;
}

@media all and (max-width: $mediaLG) {
  .button-random {
    display: none;
  }
}
