@import "../../styles/styles.scss";

@mixin common-properties {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-list {
  @include common-properties;

  .content-list-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .title {
    color: #fff;
    text-align: center;
    font-family: $font11;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .text {
    color: #fff;
    text-align: center;
    font-family: $font11;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
  }

  ul {
    list-style: none;
    height: 54px;
    flex-shrink: 0;
    padding: 0;
    margin-top: 4rem;
    height: 100%;
    max-height: 400px;
    overflow-y: scroll;

    li {
      border-radius: 6px;
      background: #fff;
      color: rgb(0, 0, 0);
      padding: $dimenM;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 400px;
      margin-bottom: $dimenXS;
    }

    li.li-selected {
      border-radius: 6px;
      border: 1px solid #aed3ff;
      background: #5c7a99;
      color: white;
      & span:nth-child(2) {
        color: #c6c6c6;
      }
    }
  }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .content-list {
    @include common-properties;
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .content-list {
    @include common-properties;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .content-list {
    @include common-properties;
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .content-list {
    @include common-properties;
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .content-list {
    @include common-properties;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .content-list {
    @include common-properties;
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  .content-list {
    @include common-properties;
  }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .content-list {
    @include common-properties;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .content-list {
    @include common-properties;
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .content-list {
    @include common-properties;
  }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .content-list {
    @include common-properties;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .content-list {
    @include common-properties;
  }
}

.custom-dropdown-gap {
  gap: $dimenS;
}
