@import 'styles.scss';

*:focus {
  outline: none;
}

#discover-page {
  background: $purple;
  margin: 0px;
  padding: 0px;
  //min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

.discover {
  gap: 100px;
  min-height: calc(100vh - 100px);
  padding-bottom: $dimenS;
}

.discover-top {
  gap: 100px;
}

.discover-bottom {
  max-width: 1204px;
  width: 90%;
  gap: 32px;
}

.discover-right, .discover-left {
  margin: 0px;
}

.discover-left img {
    margin: 0px;
    width: 100%;
    height: 100%;
}

.discover-bottom h2 {
  margin: 0px;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  color: $white;
}

.discover-right h3 {
  margin: 0px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: $dimenXXXL;
}

.discover-right h4 {
  margin: 0px;
  color: $white;
  font-family: $font3;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  margin-bottom: $dimenXL;
}

.discover-right p {
  margin: 0px;
  color: $white;
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

.discover-button-container {
  width: 100%;
  gap: 16px;
  margin-bottom: $dimenXL;
}

.discover-button-container > a {
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.discover-button-container > a:nth-child(2) {
  background: transparent;
  border: 1.5px solid $white;
}

.discover-card-bubble {
  position: absolute;
  bottom: 24px;
  right: 24px;
  margin: 0px;
  padding: 8px 22px;
  background: $white;
  border-radius: 60px;
  border: 0px;
  font-family: $font4;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: $black;
  z-index:2;
}

.discover-card-container {
  gap: 32px;
}

.discover-card {
  position: relative;
  margin: 0px;
  width: 380px;
  height: 380px;
  background: $grey-redeem;
  border: 4px solid $white;
  border-radius: 16px;
  filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.1));
}

.discover-card-left {
  position: relative;
}

.discover-card-left video {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.discover-card-left img {
  border-radius: 16px;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.discover-card-left img, .discover-card-left div {
  animation: fadeIn 9s ease-in;
}

@keyframes fadeIn {
  0% { opacity:0; }
  88% { opacity:0; }
  100% { opacity:1; }
}

.discover-card img {
  margin: 0px;
  min-height: auto;
  width: 350px;
  object-fit: cover;
  border-radius: 16px;
}

@media (max-width: 1200px) {

  .discover-top {
    gap: 8px;
  }

  .discover-card img {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: $mediaLG) {

  .discover-card-left img {
    width: 350px;
    height: 350px;
  }

  .discover {
    gap: 32px;
    min-height: calc(100vh - 126px);
  }

  .discover-top {
    gap: 40px;
  }

  .discover-bottom {
    max-width: 343px;
    width: 93%;
  }

  .discover-card-container {
    width: 100%;
    gap: 16px;
  }

  .discover-bottom h2 {
    width: 100%;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
  }

  .discover-card {
    width: 163px;
    height: 163px;
  }

  .discover-card-bubble-little {
    right: 8px;
    bottom: 8px;
    padding: 2px 10px;
  }

  .discover-right h3 {
    margin: 0px;
    margin-bottom: $dimenM;
    text-align: center;
  }

}

@media (max-width: 368px) {

  .discover-card-container {
    gap: 8px;
  }

}