@import 'styles.scss';

.coleccion-page {
    .section-header {
        height: 425px;
        background: linear-gradient(to bottom, #8A8A8A, #BEBEBE);

        .background {
            background-image: url('../assets/coleccion/background-header.png');
            background-repeat: no-repeat;
            background-position: center center;
            height: 100%;
            width: 100%;
        }
    }
    .section-filtres {
        padding-left: 84px;
        height: 54px;
        max-height: 54px;
        margin-top: 30px;

        .custom-checkbox, .custom-radio {
            opacity: 0;
            position: absolute;
            width: 0;
            height: 0;
        }

        .custom-checkbox-label, .custom-radio-label {
            padding-left: 20px;
            width: 25px;
            height: 25px;
            border: 1px solid $dark-grey;
            border-radius: 50%;
            background-color: white;
            cursor: pointer;
            position: relative;
            margin-right: 10px;
        }


        .custom-checkbox-label::after, .custom-radio-label::after {
            content: "";
            position: absolute;
            width: 60%;
            height: 60%;
            background-color: black;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 0.2s ease-in-out;
        }


        .custom-checkbox:checked + .custom-checkbox-label::after, .custom-radio:checked + .custom-radio-label::after {
            transform: translate(-50%, -50%) scale(1);
        }



        .badge-dark   {
            background: black;
            color: white;
            padding:3px 12px;
            border-radius: 12px;
            margin: 0 5px;
        }
        .accordion-button::after {
            margin-left:5px;
        }
        .filters-container {
            border-radius: 6px;
            margin-left: 3px;
            box-shadow: 0 0 10px silver;
            padding-right: 15px;

            .accordion-button:not(.collapsed) {
                background: transparent;
                border: 0;
                color: black;
            }
            .accordion-button:not(.collapsed)::after {
                background-image: var(--bs-accordion-btn-icon);
            }
        }
        .order-container {
            padding-right: 20px;
        }

        .accordion-collapse {
            position: relative;
        }

        .accordion-item {
            border: 0;
        }

        .accordion-item:focus,
        button {
            box-shadow: none;
        }

        @media all and (min-width: $mediaMinLG) {
            .filters-container {
                .accordion-button {
                    padding-right:0 !important;
                }
            }
            .order-container {
                button {
                    box-shadow: 0 0 10px silver;
                    border-radius: 6px;
                }
                .accordion-button:not(.collapsed)::after {
                    background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clipRule="evenodd" d="M6.29289 8.79289C6.68342 8.40237 7.31658 8.40237 7.70711 8.79289L12 13.0858L16.2929 8.79289C16.6834 8.40237 17.3166 8.40237 17.7071 8.79289C18.0976 9.18342 18.0976 9.81658 17.7071 10.2071L12.7071 15.2071C12.3166 15.5976 11.6834 15.5976 11.2929 15.2071L6.29289 10.2071C5.90237 9.81658 5.90237 9.18342 6.29289 8.79289Z" fill="%23ffffff"/></svg>');
                    background-size: 30px;
                    background-position: center center;
                }
                .accordion-button:not(.collapsed) {
                    background: black;
                    color: white;
                }
            }
            .accordion-item,
            .accordion-collapse,
            .accordion-body {
                transition: none !important;
            }
            .accordion-body {
                position:absolute;
                padding: $dimenXS $dimenL;
                box-shadow: 0 0 10px silver;
                top:10px;
                left: 3px;
                min-width: 250px;
                background: white;
                z-index:2;
            }
            .accordion-body.filter-order {
                right: 10px;
                left: auto;
            }
        }


        .accordion-body ul {
            list-style: none;
            margin:0;
            padding:0;
        }
        .accordion-body li {
            padding: $dimenXXS;
            display: flex;
            align-items: center;
        }
    }

    .section-selected-filters {
        padding-left: 84px;
        position: relative;
        width: 100%;


        .carousel-container {
            display: flex;
            overflow-x: auto;
            white-space: nowrap;
            padding-right: 85px;
            max-width: calc(100vw - 250px);

            /* Estilos para la barra de desplazamiento en Chrome */
            ::-webkit-scrollbar {
                width: 10px;
                background-color: transparent;
            }

            ::-webkit-scrollbar-thumb {
                background-color: transparent;
            }

            /* Estilos para la barra de desplazamiento en Firefox */
            @-moz-document url-prefix() {
                /* Solo se aplica a Firefox */
                scrollbar-color: transparent transparent;
            }

            .btn-delete-filters {
                background: black;
                border: 0;
                border-radius: 36px;
                color: white;
                padding: 0 30px 0  20px;
                margin-right: 8px;
                position: relative;

            }
            .btn-delete-filters::after {
                content: "";
                position: absolute;
                top: 51%;
                right: 12px;
                transform: translateY(-50%);
                width: 12px;
                height: 12px;
                background: black;
                background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="iso-8859-1"%3F><svg fill="%23ffffff" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve"><g><g><polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 512,452.922 315.076,256 "/></g></g></svg>');
                background-repeat: no-repeat;
                background-size: contain;
            }
            .carousel-content {
                flex-shrink: 0;
                border: 1px solid black;
                border-radius: 36px;
                padding: $dimenXXXS $dimenS;
                margin-right: $dimenXXS;
                span {
                    color: silver;
                    font-weight: bold;
                    margin-right: $dimenXXXS;
                }
                .btn-close {
                    opacity: 1;
                    margin-left: $dimenXXS;
                    width: 15px !important;
                    height:15px !important;
                    padding: 0;
                }
            }
        }

        .btn-prev,
        .btn-next {
            background: black;
            width: 30px;
            height: 30px;
            background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clipRule="evenodd" d="M6.29289 8.79289C6.68342 8.40237 7.31658 8.40237 7.70711 8.79289L12 13.0858L16.2929 8.79289C16.6834 8.40237 17.3166 8.40237 17.7071 8.79289C18.0976 9.18342 18.0976 9.81658 17.7071 10.2071L12.7071 15.2071C12.3166 15.5976 11.6834 15.5976 11.2929 15.2071L6.29289 10.2071C5.90237 9.81658 5.90237 9.18342 6.29289 8.79289Z" fill="%23ffffff"/></svg>');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            border: 0;
            border-radius: 50%;
            position:absolute;
            top:0;
            bottom:0;
            margin-top: auto;
            margin-bottom: auto;
        }
        .btn-next {
            right:20px;
            transform: rotate(270deg);
        }
        .btn-prev {
            right: 55px;
            transform: rotate(90deg);
        }

    }

    .artworks-container {
        padding-left: 84px;
        margin-top: 20px;
        .mona {
            position:relative;
            margin-top: 1%;
            .mona-wrapper {
                position: relative;
                overflow: hidden;
                padding-top: 100%;
              }
            img {
                position: absolute;
                top: 0;
                left: 0;
                height: auto;
                width: 95%;
                border-radius: 4px;
            }
            .ranking,
            .number {
                position:absolute;
                bottom: 70px;
                border-radius: 36px;
                padding:7px 15px;
            }
            .ranking {
                left: 5%;
                background: white;
                color: black;
                font-weight: bold;
            }
            .number {
                right:10%;
                background: black;
                color: white;
            }
        }
    }

    .next-render  {
        height: 100px;
        width: 100%;
    }

    .no-results {
        margin-bottom: 100px;
        color: $grey-submit;
        h2 {
            color: gray;
        }
    }
}

@media all and (max-width: $mediaLG) {
    body.no-scroll {
        overflow-y: hidden;
    }
    .coleccion-page {
        padding-bottom: 50px;
        .section-header {
            height: auto;
            width: 100%;
            padding-top: 100%;
            background: #8A8A8A;
            position: relative;

            .background {
                position: absolute; /* Permite posicionar el contenido dentro del div */
                top: 30px;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url('../assets/coleccion/background-header-mobile.png');
                background-repeat: no-repeat;
                background-position: bottom center;
                background-size: 100%;

            }
        }
        .section-selected-filters {
            display: none !important;
        }

        .section-filtres {
            padding-top: 15px;
            padding-left: 20px;

            .btn-close {
                background:transparent !important;
                color: black;
                opacity: 1;
            }
            .order-container {
                .accordion-item,
                .accordion-collapse,
                .accordion-body {
                    transition: none !important;
                }
                .accordion-button {
                    text-indent: -9999px;
                    background: white;
                    box-shadow: 0 0 10px silver;
                    border: 0;
                    height: 48px;
                    width: 48px;
                    border-radius: 4px;
                }
                .collapse:not(.show) {
                    display: block;
                }
                .accordion-body > div {
                    position:fixed;
                    top:0px;
                    left: 0px;
                    width: 100vw;
                    height: 100vh;
                    background: white;
                    z-index: 11;
                    transition: transform 0.5s, visibility 0.5s;
                    visibility: visible;
                    -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
                }
                .accordion-body > div.hidden {
                    transition: transform 0.5s, visibility 0.5s;
                    visibility: hidden;
                    -webkit-transform: translate3d(0, -100%, 0);
                    transform: translate3d(0, -100%, 0);
                }
                .accordion-button {
                    transition: none !important;
                }
                .accordion-button:not(.collapsed) {
                    background: white;
                }
                .accordion-button::after {
                    transition: none !important;
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-image: url('../assets/coleccion/btn-order-movile.png') !important;
                    background-position: center center;
                    margin-left: 0 !important;
                }
            }
            .filters-container.show {
                visibility: visible;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
            .filters-container {
                display:flex;
                margin:0 !important;
                padding:0;
                height: 100vh;
                width: 100vw;
                position: fixed;
                top:0;
                left:0;
                z-index: 10;
                background: white;
                transition: all .5s;
                visibility: hidden;
                -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
            }
            .filters-container > div {
                width: 100%;
                overflow-y: auto;
                padding-bottom: 120px;
            }
            .btn-filters-container button {
                background:white;
                box-shadow: 0 0 10px silver;
                border: 0;
                height: 48px;
                width: 48px;
                border-radius: 4px;
            }
        }
        .artworks-container {
            padding-left: 16px;
        }
        .navbar-purchase,
        .navbar-filters {
            position: fixed;
            bottom: 0;
            left: 0;
            background: white;
            width: 100vw;
            text-align: center;
            padding: 20px;
            box-shadow: 0 0 10px silver;
            z-index: 9;

            button {
                border: 1px solid black;
                width: 100%;
                border-radius: 6px;
                padding: 7px 0;
                background: white;
                color: black;
            }
            button.black {
                background: black;
                color: white;
                border: 0;
                svg {
                    width: 30px;
                    height: 30px;
                    transform: scaleX(-1);
                    margin-right: 5px;
                }
            }

            p {
                padding-top:15px;
                margin: 0;
                padding-bottom: 0;
                font-weight: bold;
            }
        }
    }
}